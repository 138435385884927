/* tslint:disable */
/* eslint-disable */
/*
 * Autogenerated by @creditkarma/thrift-typescript v3.7.6
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
*/
import * as thrift from "thrift";
import * as CommonException from "./CommonException";
import * as SR from "./SR";
import * as Ingredient from "./Ingredient";
import * as Account from "./Account";
import * as Profile from "./Profile";
import * as ProfilePageInfo from "./ProfilePageInfo";
import * as Recipe from "./Recipe";
import * as Owner from "./Owner";
import * as MenuItem from "./MenuItem";
import * as ShoppingList from "./ShoppingList";
import * as ShoppingListStatus from "./ShoppingListStatus";
import * as ManualItem from "./ManualItem";
import * as Cellar from "./Cellar";
import * as CellarItem from "./CellarItem";
import * as Party from "./Party";
import * as Premenu from "./Premenu";
export interface IPingArgsArgs {
}
export class PingArgs {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("PingArgs");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): PingArgs {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new PingArgs();
    }
}
export interface IListEditableArgsArgs {
    type: string;
}
export class ListEditableArgs {
    public type: string;
    constructor(args: IListEditableArgsArgs) {
        if (args != null && args.type != null) {
            this.type = args.type;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[type] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListEditableArgs");
        if (this.type != null) {
            output.writeFieldBegin("type", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.type);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListEditableArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_1: string = input.readString();
                        _args.type = value_1;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.type !== undefined) {
            return new ListEditableArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read ListEditableArgs from input");
        }
    }
}
export interface IListIngredientsArgsArgs {
}
export class ListIngredientsArgs {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListIngredientsArgs");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListIngredientsArgs {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListIngredientsArgs();
    }
}
export interface IGetAccountArgsArgs {
}
export class GetAccountArgs {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetAccountArgs");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetAccountArgs {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetAccountArgs();
    }
}
export interface IUpsertProfileArgsArgs {
    profile: Profile.Profile;
}
export class UpsertProfileArgs {
    public profile: Profile.Profile;
    constructor(args: IUpsertProfileArgsArgs) {
        if (args != null && args.profile != null) {
            this.profile = args.profile;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[profile] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertProfileArgs");
        if (this.profile != null) {
            output.writeFieldBegin("profile", thrift.Thrift.Type.STRUCT, 1);
            this.profile.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertProfileArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_2: Profile.Profile = Profile.Profile.read(input);
                        _args.profile = value_2;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.profile !== undefined) {
            return new UpsertProfileArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpsertProfileArgs from input");
        }
    }
}
export interface IRemoveProfileFromGroupArgsArgs {
    profileId: string;
}
export class RemoveProfileFromGroupArgs {
    public profileId: string;
    constructor(args: IRemoveProfileFromGroupArgsArgs) {
        if (args != null && args.profileId != null) {
            this.profileId = args.profileId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[profileId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("RemoveProfileFromGroupArgs");
        if (this.profileId != null) {
            output.writeFieldBegin("profileId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.profileId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): RemoveProfileFromGroupArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_3: string = input.readString();
                        _args.profileId = value_3;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.profileId !== undefined) {
            return new RemoveProfileFromGroupArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read RemoveProfileFromGroupArgs from input");
        }
    }
}
export interface IGetProfilePageInfoArgsArgs {
    id: string;
}
export class GetProfilePageInfoArgs {
    public id: string;
    constructor(args: IGetProfilePageInfoArgsArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetProfilePageInfoArgs");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetProfilePageInfoArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_4: string = input.readString();
                        _args.id = value_4;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined) {
            return new GetProfilePageInfoArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetProfilePageInfoArgs from input");
        }
    }
}
export interface IListRecipesArgsArgs {
}
export class ListRecipesArgs {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListRecipesArgs");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListRecipesArgs {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListRecipesArgs();
    }
}
export interface IGetRecipeArgsArgs {
    recipeId: string;
}
export class GetRecipeArgs {
    public recipeId: string;
    constructor(args: IGetRecipeArgsArgs) {
        if (args != null && args.recipeId != null) {
            this.recipeId = args.recipeId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[recipeId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetRecipeArgs");
        if (this.recipeId != null) {
            output.writeFieldBegin("recipeId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.recipeId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetRecipeArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_5: string = input.readString();
                        _args.recipeId = value_5;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.recipeId !== undefined) {
            return new GetRecipeArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetRecipeArgs from input");
        }
    }
}
export interface IUpsertRecipeArgsArgs {
    recipe: Recipe.Recipe;
}
export class UpsertRecipeArgs {
    public recipe: Recipe.Recipe;
    constructor(args: IUpsertRecipeArgsArgs) {
        if (args != null && args.recipe != null) {
            this.recipe = args.recipe;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[recipe] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertRecipeArgs");
        if (this.recipe != null) {
            output.writeFieldBegin("recipe", thrift.Thrift.Type.STRUCT, 1);
            this.recipe.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertRecipeArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_6: Recipe.Recipe = Recipe.Recipe.read(input);
                        _args.recipe = value_6;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.recipe !== undefined) {
            return new UpsertRecipeArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpsertRecipeArgs from input");
        }
    }
}
export interface IDeleteRecipeArgsArgs {
    recipeId: string;
}
export class DeleteRecipeArgs {
    public recipeId: string;
    constructor(args: IDeleteRecipeArgsArgs) {
        if (args != null && args.recipeId != null) {
            this.recipeId = args.recipeId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[recipeId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteRecipeArgs");
        if (this.recipeId != null) {
            output.writeFieldBegin("recipeId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.recipeId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteRecipeArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_7: string = input.readString();
                        _args.recipeId = value_7;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.recipeId !== undefined) {
            return new DeleteRecipeArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteRecipeArgs from input");
        }
    }
}
export interface IListMenuItemsArgsArgs {
    owner: Owner.Owner;
}
export class ListMenuItemsArgs {
    public owner: Owner.Owner;
    constructor(args: IListMenuItemsArgsArgs) {
        if (args != null && args.owner != null) {
            this.owner = args.owner;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[owner] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListMenuItemsArgs");
        if (this.owner != null) {
            output.writeFieldBegin("owner", thrift.Thrift.Type.STRUCT, 1);
            this.owner.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListMenuItemsArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_8: Owner.Owner = Owner.Owner.read(input);
                        _args.owner = value_8;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.owner !== undefined) {
            return new ListMenuItemsArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read ListMenuItemsArgs from input");
        }
    }
}
export interface IListMenuItemsByIdsArgsArgs {
    menuItemIds: Array<string>;
}
export class ListMenuItemsByIdsArgs {
    public menuItemIds: Array<string>;
    constructor(args: IListMenuItemsByIdsArgsArgs) {
        if (args != null && args.menuItemIds != null) {
            this.menuItemIds = args.menuItemIds;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[menuItemIds] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListMenuItemsByIdsArgs");
        if (this.menuItemIds != null) {
            output.writeFieldBegin("menuItemIds", thrift.Thrift.Type.LIST, 1);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.menuItemIds.length);
            this.menuItemIds.forEach((value_9: string): void => {
                output.writeString(value_9);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListMenuItemsByIdsArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_10: Array<string> = new Array<string>();
                        const metadata_1: thrift.TList = input.readListBegin();
                        const size_1: number = metadata_1.size;
                        for (let i_1: number = 0; i_1 < size_1; i_1++) {
                            const value_11: string = input.readString();
                            value_10.push(value_11);
                        }
                        input.readListEnd();
                        _args.menuItemIds = value_10;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.menuItemIds !== undefined) {
            return new ListMenuItemsByIdsArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read ListMenuItemsByIdsArgs from input");
        }
    }
}
export interface IUpsertMenuItemArgsArgs {
    item: MenuItem.MenuItem;
}
export class UpsertMenuItemArgs {
    public item: MenuItem.MenuItem;
    constructor(args: IUpsertMenuItemArgsArgs) {
        if (args != null && args.item != null) {
            this.item = args.item;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[item] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertMenuItemArgs");
        if (this.item != null) {
            output.writeFieldBegin("item", thrift.Thrift.Type.STRUCT, 1);
            this.item.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertMenuItemArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_12: MenuItem.MenuItem = MenuItem.MenuItem.read(input);
                        _args.item = value_12;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.item !== undefined) {
            return new UpsertMenuItemArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpsertMenuItemArgs from input");
        }
    }
}
export interface IDeleteMenuItemArgsArgs {
    id: string;
}
export class DeleteMenuItemArgs {
    public id: string;
    constructor(args: IDeleteMenuItemArgsArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteMenuItemArgs");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteMenuItemArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_13: string = input.readString();
                        _args.id = value_13;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined) {
            return new DeleteMenuItemArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteMenuItemArgs from input");
        }
    }
}
export interface IListShoppingListsArgsArgs {
    owner: Owner.Owner;
}
export class ListShoppingListsArgs {
    public owner: Owner.Owner;
    constructor(args: IListShoppingListsArgsArgs) {
        if (args != null && args.owner != null) {
            this.owner = args.owner;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[owner] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListShoppingListsArgs");
        if (this.owner != null) {
            output.writeFieldBegin("owner", thrift.Thrift.Type.STRUCT, 1);
            this.owner.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListShoppingListsArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_14: Owner.Owner = Owner.Owner.read(input);
                        _args.owner = value_14;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.owner !== undefined) {
            return new ListShoppingListsArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read ListShoppingListsArgs from input");
        }
    }
}
export interface IGetShoppingListArgsArgs {
    id: string;
}
export class GetShoppingListArgs {
    public id: string;
    constructor(args: IGetShoppingListArgsArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetShoppingListArgs");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_15: string = input.readString();
                        _args.id = value_15;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined) {
            return new GetShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetShoppingListArgs from input");
        }
    }
}
export interface ICreateShoppingListArgsArgs {
}
export class CreateShoppingListArgs {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("CreateShoppingListArgs");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): CreateShoppingListArgs {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new CreateShoppingListArgs();
    }
}
export interface ICreateShoppingListFromMenuItemsArgsArgs {
    daysBack: number;
}
export class CreateShoppingListFromMenuItemsArgs {
    public daysBack: number;
    constructor(args: ICreateShoppingListFromMenuItemsArgsArgs) {
        if (args != null && args.daysBack != null) {
            this.daysBack = args.daysBack;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[daysBack] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("CreateShoppingListFromMenuItemsArgs");
        if (this.daysBack != null) {
            output.writeFieldBegin("daysBack", thrift.Thrift.Type.I32, 1);
            output.writeI32(this.daysBack);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): CreateShoppingListFromMenuItemsArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_16: number = input.readI32();
                        _args.daysBack = value_16;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.daysBack !== undefined) {
            return new CreateShoppingListFromMenuItemsArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read CreateShoppingListFromMenuItemsArgs from input");
        }
    }
}
export interface IDeleteShoppingListArgsArgs {
    shoppingList: string;
}
export class DeleteShoppingListArgs {
    public shoppingList: string;
    constructor(args: IDeleteShoppingListArgsArgs) {
        if (args != null && args.shoppingList != null) {
            this.shoppingList = args.shoppingList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingList] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteShoppingListArgs");
        if (this.shoppingList != null) {
            output.writeFieldBegin("shoppingList", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingList);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_17: string = input.readString();
                        _args.shoppingList = value_17;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingList !== undefined) {
            return new DeleteShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteShoppingListArgs from input");
        }
    }
}
export interface ISetShoppingListStatusArgsArgs {
    shoppingList: string;
    status: ShoppingListStatus.ShoppingListStatus;
}
export class SetShoppingListStatusArgs {
    public shoppingList: string;
    public status: ShoppingListStatus.ShoppingListStatus;
    constructor(args: ISetShoppingListStatusArgsArgs) {
        if (args != null && args.shoppingList != null) {
            this.shoppingList = args.shoppingList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingList] is unset!");
        }
        if (args != null && args.status != null) {
            this.status = args.status;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[status] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("SetShoppingListStatusArgs");
        if (this.shoppingList != null) {
            output.writeFieldBegin("shoppingList", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingList);
            output.writeFieldEnd();
        }
        if (this.status != null) {
            output.writeFieldBegin("status", thrift.Thrift.Type.I32, 2);
            output.writeI32(this.status);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): SetShoppingListStatusArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_18: string = input.readString();
                        _args.shoppingList = value_18;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_19: ShoppingListStatus.ShoppingListStatus = input.readI32();
                        _args.status = value_19;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingList !== undefined && _args.status !== undefined) {
            return new SetShoppingListStatusArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SetShoppingListStatusArgs from input");
        }
    }
}
export interface ISetPurchasedStatusOnShoppingListArgsArgs {
    shoppingListId: string;
    recipeIngredientId: string;
    status: boolean;
}
export class SetPurchasedStatusOnShoppingListArgs {
    public shoppingListId: string;
    public recipeIngredientId: string;
    public status: boolean;
    constructor(args: ISetPurchasedStatusOnShoppingListArgsArgs) {
        if (args != null && args.shoppingListId != null) {
            this.shoppingListId = args.shoppingListId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingListId] is unset!");
        }
        if (args != null && args.recipeIngredientId != null) {
            this.recipeIngredientId = args.recipeIngredientId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[recipeIngredientId] is unset!");
        }
        if (args != null && args.status != null) {
            this.status = args.status;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[status] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("SetPurchasedStatusOnShoppingListArgs");
        if (this.shoppingListId != null) {
            output.writeFieldBegin("shoppingListId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingListId);
            output.writeFieldEnd();
        }
        if (this.recipeIngredientId != null) {
            output.writeFieldBegin("recipeIngredientId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.recipeIngredientId);
            output.writeFieldEnd();
        }
        if (this.status != null) {
            output.writeFieldBegin("status", thrift.Thrift.Type.BOOL, 3);
            output.writeBool(this.status);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): SetPurchasedStatusOnShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_20: string = input.readString();
                        _args.shoppingListId = value_20;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_21: string = input.readString();
                        _args.recipeIngredientId = value_21;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.BOOL) {
                        const value_22: boolean = input.readBool();
                        _args.status = value_22;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingListId !== undefined && _args.recipeIngredientId !== undefined && _args.status !== undefined) {
            return new SetPurchasedStatusOnShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SetPurchasedStatusOnShoppingListArgs from input");
        }
    }
}
export interface IAddMenuItemToShoppingListArgsArgs {
    shoppingList: string;
    menuItem: string;
}
export class AddMenuItemToShoppingListArgs {
    public shoppingList: string;
    public menuItem: string;
    constructor(args: IAddMenuItemToShoppingListArgsArgs) {
        if (args != null && args.shoppingList != null) {
            this.shoppingList = args.shoppingList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingList] is unset!");
        }
        if (args != null && args.menuItem != null) {
            this.menuItem = args.menuItem;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[menuItem] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("AddMenuItemToShoppingListArgs");
        if (this.shoppingList != null) {
            output.writeFieldBegin("shoppingList", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingList);
            output.writeFieldEnd();
        }
        if (this.menuItem != null) {
            output.writeFieldBegin("menuItem", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.menuItem);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): AddMenuItemToShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_23: string = input.readString();
                        _args.shoppingList = value_23;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_24: string = input.readString();
                        _args.menuItem = value_24;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingList !== undefined && _args.menuItem !== undefined) {
            return new AddMenuItemToShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read AddMenuItemToShoppingListArgs from input");
        }
    }
}
export interface IDeleteMenuItemFromShoppingListArgsArgs {
    shoppingList: string;
    menuItem: string;
}
export class DeleteMenuItemFromShoppingListArgs {
    public shoppingList: string;
    public menuItem: string;
    constructor(args: IDeleteMenuItemFromShoppingListArgsArgs) {
        if (args != null && args.shoppingList != null) {
            this.shoppingList = args.shoppingList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingList] is unset!");
        }
        if (args != null && args.menuItem != null) {
            this.menuItem = args.menuItem;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[menuItem] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteMenuItemFromShoppingListArgs");
        if (this.shoppingList != null) {
            output.writeFieldBegin("shoppingList", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingList);
            output.writeFieldEnd();
        }
        if (this.menuItem != null) {
            output.writeFieldBegin("menuItem", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.menuItem);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteMenuItemFromShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_25: string = input.readString();
                        _args.shoppingList = value_25;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_26: string = input.readString();
                        _args.menuItem = value_26;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingList !== undefined && _args.menuItem !== undefined) {
            return new DeleteMenuItemFromShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteMenuItemFromShoppingListArgs from input");
        }
    }
}
export interface IAddPartyToShoppingListArgsArgs {
    shoppingList: string;
    party: string;
}
export class AddPartyToShoppingListArgs {
    public shoppingList: string;
    public party: string;
    constructor(args: IAddPartyToShoppingListArgsArgs) {
        if (args != null && args.shoppingList != null) {
            this.shoppingList = args.shoppingList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingList] is unset!");
        }
        if (args != null && args.party != null) {
            this.party = args.party;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[party] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("AddPartyToShoppingListArgs");
        if (this.shoppingList != null) {
            output.writeFieldBegin("shoppingList", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingList);
            output.writeFieldEnd();
        }
        if (this.party != null) {
            output.writeFieldBegin("party", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.party);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): AddPartyToShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_27: string = input.readString();
                        _args.shoppingList = value_27;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_28: string = input.readString();
                        _args.party = value_28;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingList !== undefined && _args.party !== undefined) {
            return new AddPartyToShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read AddPartyToShoppingListArgs from input");
        }
    }
}
export interface IDeletePartyFromShoppingListArgsArgs {
    shoppingList: string;
    party: string;
}
export class DeletePartyFromShoppingListArgs {
    public shoppingList: string;
    public party: string;
    constructor(args: IDeletePartyFromShoppingListArgsArgs) {
        if (args != null && args.shoppingList != null) {
            this.shoppingList = args.shoppingList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingList] is unset!");
        }
        if (args != null && args.party != null) {
            this.party = args.party;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[party] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeletePartyFromShoppingListArgs");
        if (this.shoppingList != null) {
            output.writeFieldBegin("shoppingList", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingList);
            output.writeFieldEnd();
        }
        if (this.party != null) {
            output.writeFieldBegin("party", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.party);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeletePartyFromShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_29: string = input.readString();
                        _args.shoppingList = value_29;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_30: string = input.readString();
                        _args.party = value_30;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingList !== undefined && _args.party !== undefined) {
            return new DeletePartyFromShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeletePartyFromShoppingListArgs from input");
        }
    }
}
export interface IAddCellarItemToShoppingListArgsArgs {
    shoppingList: string;
    cellarItem: string;
}
export class AddCellarItemToShoppingListArgs {
    public shoppingList: string;
    public cellarItem: string;
    constructor(args: IAddCellarItemToShoppingListArgsArgs) {
        if (args != null && args.shoppingList != null) {
            this.shoppingList = args.shoppingList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingList] is unset!");
        }
        if (args != null && args.cellarItem != null) {
            this.cellarItem = args.cellarItem;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[cellarItem] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("AddCellarItemToShoppingListArgs");
        if (this.shoppingList != null) {
            output.writeFieldBegin("shoppingList", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingList);
            output.writeFieldEnd();
        }
        if (this.cellarItem != null) {
            output.writeFieldBegin("cellarItem", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.cellarItem);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): AddCellarItemToShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_31: string = input.readString();
                        _args.shoppingList = value_31;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_32: string = input.readString();
                        _args.cellarItem = value_32;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingList !== undefined && _args.cellarItem !== undefined) {
            return new AddCellarItemToShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read AddCellarItemToShoppingListArgs from input");
        }
    }
}
export interface IDeleteCellarItemFromShoppingListArgsArgs {
    shoppingList: string;
    cellarItem: string;
}
export class DeleteCellarItemFromShoppingListArgs {
    public shoppingList: string;
    public cellarItem: string;
    constructor(args: IDeleteCellarItemFromShoppingListArgsArgs) {
        if (args != null && args.shoppingList != null) {
            this.shoppingList = args.shoppingList;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingList] is unset!");
        }
        if (args != null && args.cellarItem != null) {
            this.cellarItem = args.cellarItem;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[cellarItem] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteCellarItemFromShoppingListArgs");
        if (this.shoppingList != null) {
            output.writeFieldBegin("shoppingList", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingList);
            output.writeFieldEnd();
        }
        if (this.cellarItem != null) {
            output.writeFieldBegin("cellarItem", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.cellarItem);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteCellarItemFromShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_33: string = input.readString();
                        _args.shoppingList = value_33;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_34: string = input.readString();
                        _args.cellarItem = value_34;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingList !== undefined && _args.cellarItem !== undefined) {
            return new DeleteCellarItemFromShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteCellarItemFromShoppingListArgs from input");
        }
    }
}
export interface IUpsertManualItemOnShoppingListArgsArgs {
    shoppingListId: string;
    item: ManualItem.ManualItem;
}
export class UpsertManualItemOnShoppingListArgs {
    public shoppingListId: string;
    public item: ManualItem.ManualItem;
    constructor(args: IUpsertManualItemOnShoppingListArgsArgs) {
        if (args != null && args.shoppingListId != null) {
            this.shoppingListId = args.shoppingListId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingListId] is unset!");
        }
        if (args != null && args.item != null) {
            this.item = args.item;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[item] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertManualItemOnShoppingListArgs");
        if (this.shoppingListId != null) {
            output.writeFieldBegin("shoppingListId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingListId);
            output.writeFieldEnd();
        }
        if (this.item != null) {
            output.writeFieldBegin("item", thrift.Thrift.Type.STRUCT, 2);
            this.item.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertManualItemOnShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_35: string = input.readString();
                        _args.shoppingListId = value_35;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_36: ManualItem.ManualItem = ManualItem.ManualItem.read(input);
                        _args.item = value_36;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingListId !== undefined && _args.item !== undefined) {
            return new UpsertManualItemOnShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpsertManualItemOnShoppingListArgs from input");
        }
    }
}
export interface IDeleteManualItemFromShoppingListArgsArgs {
    shoppingListId: string;
    itemId: string;
}
export class DeleteManualItemFromShoppingListArgs {
    public shoppingListId: string;
    public itemId: string;
    constructor(args: IDeleteManualItemFromShoppingListArgsArgs) {
        if (args != null && args.shoppingListId != null) {
            this.shoppingListId = args.shoppingListId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[shoppingListId] is unset!");
        }
        if (args != null && args.itemId != null) {
            this.itemId = args.itemId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[itemId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteManualItemFromShoppingListArgs");
        if (this.shoppingListId != null) {
            output.writeFieldBegin("shoppingListId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.shoppingListId);
            output.writeFieldEnd();
        }
        if (this.itemId != null) {
            output.writeFieldBegin("itemId", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.itemId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteManualItemFromShoppingListArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_37: string = input.readString();
                        _args.shoppingListId = value_37;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_38: string = input.readString();
                        _args.itemId = value_38;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.shoppingListId !== undefined && _args.itemId !== undefined) {
            return new DeleteManualItemFromShoppingListArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteManualItemFromShoppingListArgs from input");
        }
    }
}
export interface IGetCellarArgsArgs {
}
export class GetCellarArgs {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetCellarArgs");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetCellarArgs {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetCellarArgs();
    }
}
export interface IAddCellarItemsArgsArgs {
    item: CellarItem.CellarItem;
    howMany: number;
}
export class AddCellarItemsArgs {
    public item: CellarItem.CellarItem;
    public howMany: number;
    constructor(args: IAddCellarItemsArgsArgs) {
        if (args != null && args.item != null) {
            this.item = args.item;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[item] is unset!");
        }
        if (args != null && args.howMany != null) {
            this.howMany = args.howMany;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[howMany] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("AddCellarItemsArgs");
        if (this.item != null) {
            output.writeFieldBegin("item", thrift.Thrift.Type.STRUCT, 1);
            this.item.write(output);
            output.writeFieldEnd();
        }
        if (this.howMany != null) {
            output.writeFieldBegin("howMany", thrift.Thrift.Type.I16, 2);
            output.writeI16(this.howMany);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): AddCellarItemsArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_39: CellarItem.CellarItem = CellarItem.CellarItem.read(input);
                        _args.item = value_39;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.I16) {
                        const value_40: number = input.readI16();
                        _args.howMany = value_40;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.item !== undefined && _args.howMany !== undefined) {
            return new AddCellarItemsArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read AddCellarItemsArgs from input");
        }
    }
}
export interface IDeleteCellarItemArgsArgs {
    id: string;
}
export class DeleteCellarItemArgs {
    public id: string;
    constructor(args: IDeleteCellarItemArgsArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteCellarItemArgs");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteCellarItemArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_41: string = input.readString();
                        _args.id = value_41;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined) {
            return new DeleteCellarItemArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeleteCellarItemArgs from input");
        }
    }
}
export interface ISetCellarItemBrokenArgsArgs {
    itemId: string;
}
export class SetCellarItemBrokenArgs {
    public itemId: string;
    constructor(args: ISetCellarItemBrokenArgsArgs) {
        if (args != null && args.itemId != null) {
            this.itemId = args.itemId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[itemId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("SetCellarItemBrokenArgs");
        if (this.itemId != null) {
            output.writeFieldBegin("itemId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.itemId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): SetCellarItemBrokenArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_42: string = input.readString();
                        _args.itemId = value_42;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.itemId !== undefined) {
            return new SetCellarItemBrokenArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read SetCellarItemBrokenArgs from input");
        }
    }
}
export interface IUnsetCellarItemBrokenArgsArgs {
    itemId: string;
}
export class UnsetCellarItemBrokenArgs {
    public itemId: string;
    constructor(args: IUnsetCellarItemBrokenArgsArgs) {
        if (args != null && args.itemId != null) {
            this.itemId = args.itemId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[itemId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UnsetCellarItemBrokenArgs");
        if (this.itemId != null) {
            output.writeFieldBegin("itemId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.itemId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UnsetCellarItemBrokenArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_43: string = input.readString();
                        _args.itemId = value_43;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.itemId !== undefined) {
            return new UnsetCellarItemBrokenArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UnsetCellarItemBrokenArgs from input");
        }
    }
}
export interface IListPartiesArgsArgs {
}
export class ListPartiesArgs {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListPartiesArgs");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListPartiesArgs {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListPartiesArgs();
    }
}
export interface IUpsertPartyArgsArgs {
    party: Party.Party;
}
export class UpsertPartyArgs {
    public party: Party.Party;
    constructor(args: IUpsertPartyArgsArgs) {
        if (args != null && args.party != null) {
            this.party = args.party;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[party] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertPartyArgs");
        if (this.party != null) {
            output.writeFieldBegin("party", thrift.Thrift.Type.STRUCT, 1);
            this.party.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertPartyArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_44: Party.Party = Party.Party.read(input);
                        _args.party = value_44;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.party !== undefined) {
            return new UpsertPartyArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpsertPartyArgs from input");
        }
    }
}
export interface IDeletePartyArgsArgs {
    id: string;
}
export class DeletePartyArgs {
    public id: string;
    constructor(args: IDeletePartyArgsArgs) {
        if (args != null && args.id != null) {
            this.id = args.id;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[id] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeletePartyArgs");
        if (this.id != null) {
            output.writeFieldBegin("id", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.id);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeletePartyArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_45: string = input.readString();
                        _args.id = value_45;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.id !== undefined) {
            return new DeletePartyArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeletePartyArgs from input");
        }
    }
}
export interface IGetPartyArgsArgs {
    partyId: string;
}
export class GetPartyArgs {
    public partyId: string;
    constructor(args: IGetPartyArgsArgs) {
        if (args != null && args.partyId != null) {
            this.partyId = args.partyId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[partyId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetPartyArgs");
        if (this.partyId != null) {
            output.writeFieldBegin("partyId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.partyId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetPartyArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_46: string = input.readString();
                        _args.partyId = value_46;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.partyId !== undefined) {
            return new GetPartyArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetPartyArgs from input");
        }
    }
}
export interface IListPremenusArgsArgs {
}
export class ListPremenusArgs {
    constructor() {
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListPremenusArgs");
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListPremenusArgs {
        input.readStructBegin();
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListPremenusArgs();
    }
}
export interface IGetPremenuArgsArgs {
    premenuId: string;
}
export class GetPremenuArgs {
    public premenuId: string;
    constructor(args: IGetPremenuArgsArgs) {
        if (args != null && args.premenuId != null) {
            this.premenuId = args.premenuId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[premenuId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetPremenuArgs");
        if (this.premenuId != null) {
            output.writeFieldBegin("premenuId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.premenuId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetPremenuArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_47: string = input.readString();
                        _args.premenuId = value_47;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.premenuId !== undefined) {
            return new GetPremenuArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read GetPremenuArgs from input");
        }
    }
}
export interface IDeletePremenuArgsArgs {
    premenuId: string;
}
export class DeletePremenuArgs {
    public premenuId: string;
    constructor(args: IDeletePremenuArgsArgs) {
        if (args != null && args.premenuId != null) {
            this.premenuId = args.premenuId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[premenuId] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeletePremenuArgs");
        if (this.premenuId != null) {
            output.writeFieldBegin("premenuId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.premenuId);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeletePremenuArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_48: string = input.readString();
                        _args.premenuId = value_48;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.premenuId !== undefined) {
            return new DeletePremenuArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read DeletePremenuArgs from input");
        }
    }
}
export interface IUpsertPremenuArgsArgs {
    premenu: Premenu.Premenu;
}
export class UpsertPremenuArgs {
    public premenu: Premenu.Premenu;
    constructor(args: IUpsertPremenuArgsArgs) {
        if (args != null && args.premenu != null) {
            this.premenu = args.premenu;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[premenu] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertPremenuArgs");
        if (this.premenu != null) {
            output.writeFieldBegin("premenu", thrift.Thrift.Type.STRUCT, 1);
            this.premenu.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertPremenuArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_49: Premenu.Premenu = Premenu.Premenu.read(input);
                        _args.premenu = value_49;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.premenu !== undefined) {
            return new UpsertPremenuArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UpsertPremenuArgs from input");
        }
    }
}
export interface IUsePremenuArgsArgs {
    premenuId: string;
    startDate: string;
    premenuStartDate: string;
    daysNum: number;
    factor: number;
}
export class UsePremenuArgs {
    public premenuId: string;
    public startDate: string;
    public premenuStartDate: string;
    public daysNum: number;
    public factor: number;
    constructor(args: IUsePremenuArgsArgs) {
        if (args != null && args.premenuId != null) {
            this.premenuId = args.premenuId;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[premenuId] is unset!");
        }
        if (args != null && args.startDate != null) {
            this.startDate = args.startDate;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[startDate] is unset!");
        }
        if (args != null && args.premenuStartDate != null) {
            this.premenuStartDate = args.premenuStartDate;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[premenuStartDate] is unset!");
        }
        if (args != null && args.daysNum != null) {
            this.daysNum = args.daysNum;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[daysNum] is unset!");
        }
        if (args != null && args.factor != null) {
            this.factor = args.factor;
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Required field[factor] is unset!");
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UsePremenuArgs");
        if (this.premenuId != null) {
            output.writeFieldBegin("premenuId", thrift.Thrift.Type.STRING, 1);
            output.writeString(this.premenuId);
            output.writeFieldEnd();
        }
        if (this.startDate != null) {
            output.writeFieldBegin("startDate", thrift.Thrift.Type.STRING, 2);
            output.writeString(this.startDate);
            output.writeFieldEnd();
        }
        if (this.premenuStartDate != null) {
            output.writeFieldBegin("premenuStartDate", thrift.Thrift.Type.STRING, 3);
            output.writeString(this.premenuStartDate);
            output.writeFieldEnd();
        }
        if (this.daysNum != null) {
            output.writeFieldBegin("daysNum", thrift.Thrift.Type.I32, 4);
            output.writeI32(this.daysNum);
            output.writeFieldEnd();
        }
        if (this.factor != null) {
            output.writeFieldBegin("factor", thrift.Thrift.Type.DOUBLE, 5);
            output.writeDouble(this.factor);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UsePremenuArgs {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_50: string = input.readString();
                        _args.premenuId = value_50;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 2:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_51: string = input.readString();
                        _args.startDate = value_51;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 3:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_52: string = input.readString();
                        _args.premenuStartDate = value_52;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 4:
                    if (fieldType === thrift.Thrift.Type.I32) {
                        const value_53: number = input.readI32();
                        _args.daysNum = value_53;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 5:
                    if (fieldType === thrift.Thrift.Type.DOUBLE) {
                        const value_54: number = input.readDouble();
                        _args.factor = value_54;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        if (_args.premenuId !== undefined && _args.startDate !== undefined && _args.premenuStartDate !== undefined && _args.daysNum !== undefined && _args.factor !== undefined) {
            return new UsePremenuArgs(_args);
        }
        else {
            throw new thrift.Thrift.TProtocolException(thrift.Thrift.TProtocolExceptionType.UNKNOWN, "Unable to read UsePremenuArgs from input");
        }
    }
}
export interface IPingResultArgs {
    success?: string;
}
export class PingResult {
    public success?: string;
    constructor(args?: IPingResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("PingResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): PingResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_55: string = input.readString();
                        _args.success = value_55;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new PingResult(_args);
    }
}
export interface IListEditableResultArgs {
    success?: Array<SR.SR>;
    ce?: CommonException.CommonException;
}
export class ListEditableResult {
    public success?: Array<SR.SR>;
    public ce?: CommonException.CommonException;
    constructor(args?: IListEditableResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListEditableResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.success.length);
            this.success.forEach((value_56: SR.SR): void => {
                value_56.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListEditableResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_57: Array<SR.SR> = new Array<SR.SR>();
                        const metadata_2: thrift.TList = input.readListBegin();
                        const size_2: number = metadata_2.size;
                        for (let i_2: number = 0; i_2 < size_2; i_2++) {
                            const value_58: SR.SR = SR.SR.read(input);
                            value_57.push(value_58);
                        }
                        input.readListEnd();
                        _args.success = value_57;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_59: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_59;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListEditableResult(_args);
    }
}
export interface IListIngredientsResultArgs {
    success?: Array<Ingredient.Ingredient>;
    ce?: CommonException.CommonException;
}
export class ListIngredientsResult {
    public success?: Array<Ingredient.Ingredient>;
    public ce?: CommonException.CommonException;
    constructor(args?: IListIngredientsResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListIngredientsResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.success.length);
            this.success.forEach((value_60: Ingredient.Ingredient): void => {
                value_60.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListIngredientsResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_61: Array<Ingredient.Ingredient> = new Array<Ingredient.Ingredient>();
                        const metadata_3: thrift.TList = input.readListBegin();
                        const size_3: number = metadata_3.size;
                        for (let i_3: number = 0; i_3 < size_3; i_3++) {
                            const value_62: Ingredient.Ingredient = Ingredient.Ingredient.read(input);
                            value_61.push(value_62);
                        }
                        input.readListEnd();
                        _args.success = value_61;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_63: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_63;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListIngredientsResult(_args);
    }
}
export interface IGetAccountResultArgs {
    success?: Account.Account;
    ce?: CommonException.CommonException;
}
export class GetAccountResult {
    public success?: Account.Account;
    public ce?: CommonException.CommonException;
    constructor(args?: IGetAccountResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetAccountResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
            this.success.write(output);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetAccountResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_64: Account.Account = Account.Account.read(input);
                        _args.success = value_64;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_65: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_65;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetAccountResult(_args);
    }
}
export interface IUpsertProfileResultArgs {
    success?: string;
    ce?: CommonException.CommonException;
}
export class UpsertProfileResult {
    public success?: string;
    public ce?: CommonException.CommonException;
    constructor(args?: IUpsertProfileResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertProfileResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertProfileResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_66: string = input.readString();
                        _args.success = value_66;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_67: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_67;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new UpsertProfileResult(_args);
    }
}
export interface IRemoveProfileFromGroupResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class RemoveProfileFromGroupResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IRemoveProfileFromGroupResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("RemoveProfileFromGroupResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): RemoveProfileFromGroupResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_68: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_68;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new RemoveProfileFromGroupResult(_args);
    }
}
export interface IGetProfilePageInfoResultArgs {
    success?: ProfilePageInfo.ProfilePageInfo;
    ce?: CommonException.CommonException;
}
export class GetProfilePageInfoResult {
    public success?: ProfilePageInfo.ProfilePageInfo;
    public ce?: CommonException.CommonException;
    constructor(args?: IGetProfilePageInfoResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetProfilePageInfoResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
            this.success.write(output);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetProfilePageInfoResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_69: ProfilePageInfo.ProfilePageInfo = ProfilePageInfo.ProfilePageInfo.read(input);
                        _args.success = value_69;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_70: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_70;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetProfilePageInfoResult(_args);
    }
}
export interface IListRecipesResultArgs {
    success?: Array<Recipe.Recipe>;
    ce?: CommonException.CommonException;
}
export class ListRecipesResult {
    public success?: Array<Recipe.Recipe>;
    public ce?: CommonException.CommonException;
    constructor(args?: IListRecipesResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListRecipesResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.success.length);
            this.success.forEach((value_71: Recipe.Recipe): void => {
                value_71.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListRecipesResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_72: Array<Recipe.Recipe> = new Array<Recipe.Recipe>();
                        const metadata_4: thrift.TList = input.readListBegin();
                        const size_4: number = metadata_4.size;
                        for (let i_4: number = 0; i_4 < size_4; i_4++) {
                            const value_73: Recipe.Recipe = Recipe.Recipe.read(input);
                            value_72.push(value_73);
                        }
                        input.readListEnd();
                        _args.success = value_72;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_74: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_74;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListRecipesResult(_args);
    }
}
export interface IGetRecipeResultArgs {
    success?: Recipe.Recipe;
    ce?: CommonException.CommonException;
}
export class GetRecipeResult {
    public success?: Recipe.Recipe;
    public ce?: CommonException.CommonException;
    constructor(args?: IGetRecipeResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetRecipeResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
            this.success.write(output);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetRecipeResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_75: Recipe.Recipe = Recipe.Recipe.read(input);
                        _args.success = value_75;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_76: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_76;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetRecipeResult(_args);
    }
}
export interface IUpsertRecipeResultArgs {
    success?: string;
    ce?: CommonException.CommonException;
}
export class UpsertRecipeResult {
    public success?: string;
    public ce?: CommonException.CommonException;
    constructor(args?: IUpsertRecipeResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertRecipeResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertRecipeResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_77: string = input.readString();
                        _args.success = value_77;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_78: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_78;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new UpsertRecipeResult(_args);
    }
}
export interface IDeleteRecipeResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeleteRecipeResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeleteRecipeResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteRecipeResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteRecipeResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_79: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_79;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeleteRecipeResult(_args);
    }
}
export interface IListMenuItemsResultArgs {
    success?: Array<MenuItem.MenuItem>;
    ce?: CommonException.CommonException;
}
export class ListMenuItemsResult {
    public success?: Array<MenuItem.MenuItem>;
    public ce?: CommonException.CommonException;
    constructor(args?: IListMenuItemsResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListMenuItemsResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.success.length);
            this.success.forEach((value_80: MenuItem.MenuItem): void => {
                value_80.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListMenuItemsResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_81: Array<MenuItem.MenuItem> = new Array<MenuItem.MenuItem>();
                        const metadata_5: thrift.TList = input.readListBegin();
                        const size_5: number = metadata_5.size;
                        for (let i_5: number = 0; i_5 < size_5; i_5++) {
                            const value_82: MenuItem.MenuItem = MenuItem.MenuItem.read(input);
                            value_81.push(value_82);
                        }
                        input.readListEnd();
                        _args.success = value_81;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_83: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_83;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListMenuItemsResult(_args);
    }
}
export interface IListMenuItemsByIdsResultArgs {
    success?: Array<MenuItem.MenuItem>;
    ce?: CommonException.CommonException;
}
export class ListMenuItemsByIdsResult {
    public success?: Array<MenuItem.MenuItem>;
    public ce?: CommonException.CommonException;
    constructor(args?: IListMenuItemsByIdsResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListMenuItemsByIdsResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.success.length);
            this.success.forEach((value_84: MenuItem.MenuItem): void => {
                value_84.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListMenuItemsByIdsResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_85: Array<MenuItem.MenuItem> = new Array<MenuItem.MenuItem>();
                        const metadata_6: thrift.TList = input.readListBegin();
                        const size_6: number = metadata_6.size;
                        for (let i_6: number = 0; i_6 < size_6; i_6++) {
                            const value_86: MenuItem.MenuItem = MenuItem.MenuItem.read(input);
                            value_85.push(value_86);
                        }
                        input.readListEnd();
                        _args.success = value_85;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_87: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_87;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListMenuItemsByIdsResult(_args);
    }
}
export interface IUpsertMenuItemResultArgs {
    success?: string;
    ce?: CommonException.CommonException;
}
export class UpsertMenuItemResult {
    public success?: string;
    public ce?: CommonException.CommonException;
    constructor(args?: IUpsertMenuItemResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertMenuItemResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertMenuItemResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_88: string = input.readString();
                        _args.success = value_88;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_89: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_89;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new UpsertMenuItemResult(_args);
    }
}
export interface IDeleteMenuItemResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeleteMenuItemResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeleteMenuItemResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteMenuItemResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteMenuItemResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_90: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_90;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeleteMenuItemResult(_args);
    }
}
export interface IListShoppingListsResultArgs {
    success?: Array<ShoppingList.ShoppingList>;
    ce?: CommonException.CommonException;
}
export class ListShoppingListsResult {
    public success?: Array<ShoppingList.ShoppingList>;
    public ce?: CommonException.CommonException;
    constructor(args?: IListShoppingListsResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListShoppingListsResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.success.length);
            this.success.forEach((value_91: ShoppingList.ShoppingList): void => {
                value_91.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListShoppingListsResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_92: Array<ShoppingList.ShoppingList> = new Array<ShoppingList.ShoppingList>();
                        const metadata_7: thrift.TList = input.readListBegin();
                        const size_7: number = metadata_7.size;
                        for (let i_7: number = 0; i_7 < size_7; i_7++) {
                            const value_93: ShoppingList.ShoppingList = ShoppingList.ShoppingList.read(input);
                            value_92.push(value_93);
                        }
                        input.readListEnd();
                        _args.success = value_92;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_94: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_94;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListShoppingListsResult(_args);
    }
}
export interface IGetShoppingListResultArgs {
    success?: ShoppingList.ShoppingList;
    ce?: CommonException.CommonException;
}
export class GetShoppingListResult {
    public success?: ShoppingList.ShoppingList;
    public ce?: CommonException.CommonException;
    constructor(args?: IGetShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetShoppingListResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
            this.success.write(output);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_95: ShoppingList.ShoppingList = ShoppingList.ShoppingList.read(input);
                        _args.success = value_95;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_96: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_96;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetShoppingListResult(_args);
    }
}
export interface ICreateShoppingListResultArgs {
    success?: string;
    ce?: CommonException.CommonException;
}
export class CreateShoppingListResult {
    public success?: string;
    public ce?: CommonException.CommonException;
    constructor(args?: ICreateShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("CreateShoppingListResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): CreateShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_97: string = input.readString();
                        _args.success = value_97;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_98: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_98;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new CreateShoppingListResult(_args);
    }
}
export interface ICreateShoppingListFromMenuItemsResultArgs {
    success?: string;
    ce?: CommonException.CommonException;
}
export class CreateShoppingListFromMenuItemsResult {
    public success?: string;
    public ce?: CommonException.CommonException;
    constructor(args?: ICreateShoppingListFromMenuItemsResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("CreateShoppingListFromMenuItemsResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): CreateShoppingListFromMenuItemsResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_99: string = input.readString();
                        _args.success = value_99;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_100: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_100;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new CreateShoppingListFromMenuItemsResult(_args);
    }
}
export interface IDeleteShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeleteShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeleteShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_101: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_101;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeleteShoppingListResult(_args);
    }
}
export interface ISetShoppingListStatusResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class SetShoppingListStatusResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: ISetShoppingListStatusResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("SetShoppingListStatusResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): SetShoppingListStatusResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_102: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_102;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new SetShoppingListStatusResult(_args);
    }
}
export interface ISetPurchasedStatusOnShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class SetPurchasedStatusOnShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: ISetPurchasedStatusOnShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("SetPurchasedStatusOnShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): SetPurchasedStatusOnShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_103: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_103;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new SetPurchasedStatusOnShoppingListResult(_args);
    }
}
export interface IAddMenuItemToShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class AddMenuItemToShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IAddMenuItemToShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("AddMenuItemToShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): AddMenuItemToShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_104: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_104;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new AddMenuItemToShoppingListResult(_args);
    }
}
export interface IDeleteMenuItemFromShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeleteMenuItemFromShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeleteMenuItemFromShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteMenuItemFromShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteMenuItemFromShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_105: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_105;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeleteMenuItemFromShoppingListResult(_args);
    }
}
export interface IAddPartyToShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class AddPartyToShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IAddPartyToShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("AddPartyToShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): AddPartyToShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_106: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_106;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new AddPartyToShoppingListResult(_args);
    }
}
export interface IDeletePartyFromShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeletePartyFromShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeletePartyFromShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeletePartyFromShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeletePartyFromShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_107: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_107;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeletePartyFromShoppingListResult(_args);
    }
}
export interface IAddCellarItemToShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class AddCellarItemToShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IAddCellarItemToShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("AddCellarItemToShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): AddCellarItemToShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_108: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_108;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new AddCellarItemToShoppingListResult(_args);
    }
}
export interface IDeleteCellarItemFromShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeleteCellarItemFromShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeleteCellarItemFromShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteCellarItemFromShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteCellarItemFromShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_109: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_109;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeleteCellarItemFromShoppingListResult(_args);
    }
}
export interface IUpsertManualItemOnShoppingListResultArgs {
    success?: string;
    ce?: CommonException.CommonException;
}
export class UpsertManualItemOnShoppingListResult {
    public success?: string;
    public ce?: CommonException.CommonException;
    constructor(args?: IUpsertManualItemOnShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertManualItemOnShoppingListResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertManualItemOnShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_110: string = input.readString();
                        _args.success = value_110;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_111: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_111;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new UpsertManualItemOnShoppingListResult(_args);
    }
}
export interface IDeleteManualItemFromShoppingListResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeleteManualItemFromShoppingListResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeleteManualItemFromShoppingListResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteManualItemFromShoppingListResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteManualItemFromShoppingListResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_112: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_112;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeleteManualItemFromShoppingListResult(_args);
    }
}
export interface IGetCellarResultArgs {
    success?: Cellar.Cellar;
    ce?: CommonException.CommonException;
}
export class GetCellarResult {
    public success?: Cellar.Cellar;
    public ce?: CommonException.CommonException;
    constructor(args?: IGetCellarResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetCellarResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
            this.success.write(output);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetCellarResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_113: Cellar.Cellar = Cellar.Cellar.read(input);
                        _args.success = value_113;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_114: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_114;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetCellarResult(_args);
    }
}
export interface IAddCellarItemsResultArgs {
    success?: Array<string>;
    ce?: CommonException.CommonException;
}
export class AddCellarItemsResult {
    public success?: Array<string>;
    public ce?: CommonException.CommonException;
    constructor(args?: IAddCellarItemsResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("AddCellarItemsResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.success.length);
            this.success.forEach((value_115: string): void => {
                output.writeString(value_115);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): AddCellarItemsResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_116: Array<string> = new Array<string>();
                        const metadata_8: thrift.TList = input.readListBegin();
                        const size_8: number = metadata_8.size;
                        for (let i_8: number = 0; i_8 < size_8; i_8++) {
                            const value_117: string = input.readString();
                            value_116.push(value_117);
                        }
                        input.readListEnd();
                        _args.success = value_116;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_118: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_118;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new AddCellarItemsResult(_args);
    }
}
export interface IDeleteCellarItemResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeleteCellarItemResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeleteCellarItemResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeleteCellarItemResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeleteCellarItemResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_119: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_119;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeleteCellarItemResult(_args);
    }
}
export interface ISetCellarItemBrokenResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class SetCellarItemBrokenResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: ISetCellarItemBrokenResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("SetCellarItemBrokenResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): SetCellarItemBrokenResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_120: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_120;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new SetCellarItemBrokenResult(_args);
    }
}
export interface IUnsetCellarItemBrokenResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class UnsetCellarItemBrokenResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IUnsetCellarItemBrokenResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UnsetCellarItemBrokenResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UnsetCellarItemBrokenResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_121: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_121;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new UnsetCellarItemBrokenResult(_args);
    }
}
export interface IListPartiesResultArgs {
    success?: Array<Party.Party>;
    ce?: CommonException.CommonException;
}
export class ListPartiesResult {
    public success?: Array<Party.Party>;
    public ce?: CommonException.CommonException;
    constructor(args?: IListPartiesResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListPartiesResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.success.length);
            this.success.forEach((value_122: Party.Party): void => {
                value_122.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListPartiesResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_123: Array<Party.Party> = new Array<Party.Party>();
                        const metadata_9: thrift.TList = input.readListBegin();
                        const size_9: number = metadata_9.size;
                        for (let i_9: number = 0; i_9 < size_9; i_9++) {
                            const value_124: Party.Party = Party.Party.read(input);
                            value_123.push(value_124);
                        }
                        input.readListEnd();
                        _args.success = value_123;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_125: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_125;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListPartiesResult(_args);
    }
}
export interface IUpsertPartyResultArgs {
    success?: string;
    ce?: CommonException.CommonException;
}
export class UpsertPartyResult {
    public success?: string;
    public ce?: CommonException.CommonException;
    constructor(args?: IUpsertPartyResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertPartyResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertPartyResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_126: string = input.readString();
                        _args.success = value_126;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_127: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_127;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new UpsertPartyResult(_args);
    }
}
export interface IDeletePartyResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeletePartyResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeletePartyResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeletePartyResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeletePartyResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_128: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_128;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeletePartyResult(_args);
    }
}
export interface IGetPartyResultArgs {
    success?: Party.Party;
    ce?: CommonException.CommonException;
}
export class GetPartyResult {
    public success?: Party.Party;
    public ce?: CommonException.CommonException;
    constructor(args?: IGetPartyResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetPartyResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
            this.success.write(output);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetPartyResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_129: Party.Party = Party.Party.read(input);
                        _args.success = value_129;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_130: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_130;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetPartyResult(_args);
    }
}
export interface IListPremenusResultArgs {
    success?: Array<Premenu.Premenu>;
    ce?: CommonException.CommonException;
}
export class ListPremenusResult {
    public success?: Array<Premenu.Premenu>;
    public ce?: CommonException.CommonException;
    constructor(args?: IListPremenusResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("ListPremenusResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRUCT, this.success.length);
            this.success.forEach((value_131: Premenu.Premenu): void => {
                value_131.write(output);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): ListPremenusResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_132: Array<Premenu.Premenu> = new Array<Premenu.Premenu>();
                        const metadata_10: thrift.TList = input.readListBegin();
                        const size_10: number = metadata_10.size;
                        for (let i_10: number = 0; i_10 < size_10; i_10++) {
                            const value_133: Premenu.Premenu = Premenu.Premenu.read(input);
                            value_132.push(value_133);
                        }
                        input.readListEnd();
                        _args.success = value_132;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_134: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_134;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new ListPremenusResult(_args);
    }
}
export interface IGetPremenuResultArgs {
    success?: Premenu.Premenu;
    ce?: CommonException.CommonException;
}
export class GetPremenuResult {
    public success?: Premenu.Premenu;
    public ce?: CommonException.CommonException;
    constructor(args?: IGetPremenuResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("GetPremenuResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRUCT, 0);
            this.success.write(output);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): GetPremenuResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_135: Premenu.Premenu = Premenu.Premenu.read(input);
                        _args.success = value_135;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_136: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_136;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new GetPremenuResult(_args);
    }
}
export interface IDeletePremenuResultArgs {
    success?: void;
    ce?: CommonException.CommonException;
}
export class DeletePremenuResult {
    public success?: void;
    public ce?: CommonException.CommonException;
    constructor(args?: IDeletePremenuResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("DeletePremenuResult");
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): DeletePremenuResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.VOID) {
                        input.skip(fieldType);
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_137: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_137;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new DeletePremenuResult(_args);
    }
}
export interface IUpsertPremenuResultArgs {
    success?: string;
    ce?: CommonException.CommonException;
}
export class UpsertPremenuResult {
    public success?: string;
    public ce?: CommonException.CommonException;
    constructor(args?: IUpsertPremenuResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UpsertPremenuResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.STRING, 0);
            output.writeString(this.success);
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UpsertPremenuResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.STRING) {
                        const value_138: string = input.readString();
                        _args.success = value_138;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_139: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_139;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new UpsertPremenuResult(_args);
    }
}
export interface IUsePremenuResultArgs {
    success?: Array<string>;
    ce?: CommonException.CommonException;
}
export class UsePremenuResult {
    public success?: Array<string>;
    public ce?: CommonException.CommonException;
    constructor(args?: IUsePremenuResultArgs) {
        if (args != null && args.success != null) {
            this.success = args.success;
        }
        if (args != null && args.ce != null) {
            this.ce = args.ce;
        }
    }
    public write(output: thrift.TProtocol): void {
        output.writeStructBegin("UsePremenuResult");
        if (this.success != null) {
            output.writeFieldBegin("success", thrift.Thrift.Type.LIST, 0);
            output.writeListBegin(thrift.Thrift.Type.STRING, this.success.length);
            this.success.forEach((value_140: string): void => {
                output.writeString(value_140);
            });
            output.writeListEnd();
            output.writeFieldEnd();
        }
        if (this.ce != null) {
            output.writeFieldBegin("ce", thrift.Thrift.Type.STRUCT, 1);
            this.ce.write(output);
            output.writeFieldEnd();
        }
        output.writeFieldStop();
        output.writeStructEnd();
        return;
    }
    public static read(input: thrift.TProtocol): UsePremenuResult {
        input.readStructBegin();
        let _args: any = {};
        while (true) {
            const ret: thrift.TField = input.readFieldBegin();
            const fieldType: thrift.Thrift.Type = ret.ftype;
            const fieldId: number = ret.fid;
            if (fieldType === thrift.Thrift.Type.STOP) {
                break;
            }
            switch (fieldId) {
                case 0:
                    if (fieldType === thrift.Thrift.Type.LIST) {
                        const value_141: Array<string> = new Array<string>();
                        const metadata_11: thrift.TList = input.readListBegin();
                        const size_11: number = metadata_11.size;
                        for (let i_11: number = 0; i_11 < size_11; i_11++) {
                            const value_142: string = input.readString();
                            value_141.push(value_142);
                        }
                        input.readListEnd();
                        _args.success = value_141;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                case 1:
                    if (fieldType === thrift.Thrift.Type.STRUCT) {
                        const value_143: CommonException.CommonException = CommonException.CommonException.read(input);
                        _args.ce = value_143;
                    }
                    else {
                        input.skip(fieldType);
                    }
                    break;
                default: {
                    input.skip(fieldType);
                }
            }
            input.readFieldEnd();
        }
        input.readStructEnd();
        return new UsePremenuResult(_args);
    }
}
export class Client {
    public _seqid: number;
    public _reqs: {
        [name: number]: (err: Error | object | undefined, val?: any) => void;
    };
    public output: thrift.TTransport;
    public protocol: new (trans: thrift.TTransport) => thrift.TProtocol;
    constructor(output: thrift.TTransport, protocol: new (trans: thrift.TTransport) => thrift.TProtocol) {
        this._seqid = 0;
        this._reqs = {};
        this.output = output;
        this.protocol = protocol;
    }
    public incrementSeqId(): number {
        return this._seqid += 1;
    }
    public ping(): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_ping(requestId);
        });
    }
    public listEditable(type: string): Promise<Array<SR.SR>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<SR.SR>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_listEditable(type, requestId);
        });
    }
    public listIngredients(): Promise<Array<Ingredient.Ingredient>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<Ingredient.Ingredient>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_listIngredients(requestId);
        });
    }
    public getAccount(): Promise<Account.Account> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Account.Account>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_getAccount(requestId);
        });
    }
    public upsertProfile(profile: Profile.Profile): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_upsertProfile(profile, requestId);
        });
    }
    public removeProfileFromGroup(profileId: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_removeProfileFromGroup(profileId, requestId);
        });
    }
    public getProfilePageInfo(id: string): Promise<ProfilePageInfo.ProfilePageInfo> {
        const requestId: number = this.incrementSeqId();
        return new Promise<ProfilePageInfo.ProfilePageInfo>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_getProfilePageInfo(id, requestId);
        });
    }
    public listRecipes(): Promise<Array<Recipe.Recipe>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<Recipe.Recipe>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_listRecipes(requestId);
        });
    }
    public getRecipe(recipeId: string): Promise<Recipe.Recipe> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Recipe.Recipe>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_getRecipe(recipeId, requestId);
        });
    }
    public upsertRecipe(recipe: Recipe.Recipe): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_upsertRecipe(recipe, requestId);
        });
    }
    public deleteRecipe(recipeId: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deleteRecipe(recipeId, requestId);
        });
    }
    public listMenuItems(owner: Owner.Owner): Promise<Array<MenuItem.MenuItem>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<MenuItem.MenuItem>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_listMenuItems(owner, requestId);
        });
    }
    public listMenuItemsByIds(menuItemIds: Array<string>): Promise<Array<MenuItem.MenuItem>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<MenuItem.MenuItem>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_listMenuItemsByIds(menuItemIds, requestId);
        });
    }
    public upsertMenuItem(item: MenuItem.MenuItem): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_upsertMenuItem(item, requestId);
        });
    }
    public deleteMenuItem(id: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deleteMenuItem(id, requestId);
        });
    }
    public listShoppingLists(owner: Owner.Owner): Promise<Array<ShoppingList.ShoppingList>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<ShoppingList.ShoppingList>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_listShoppingLists(owner, requestId);
        });
    }
    public getShoppingList(id: string): Promise<ShoppingList.ShoppingList> {
        const requestId: number = this.incrementSeqId();
        return new Promise<ShoppingList.ShoppingList>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_getShoppingList(id, requestId);
        });
    }
    public createShoppingList(): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_createShoppingList(requestId);
        });
    }
    public createShoppingListFromMenuItems(daysBack: number): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_createShoppingListFromMenuItems(daysBack, requestId);
        });
    }
    public deleteShoppingList(shoppingList: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deleteShoppingList(shoppingList, requestId);
        });
    }
    public setShoppingListStatus(shoppingList: string, status: ShoppingListStatus.ShoppingListStatus): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_setShoppingListStatus(shoppingList, status, requestId);
        });
    }
    public setPurchasedStatusOnShoppingList(shoppingListId: string, recipeIngredientId: string, status: boolean): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_setPurchasedStatusOnShoppingList(shoppingListId, recipeIngredientId, status, requestId);
        });
    }
    public addMenuItemToShoppingList(shoppingList: string, menuItem: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_addMenuItemToShoppingList(shoppingList, menuItem, requestId);
        });
    }
    public deleteMenuItemFromShoppingList(shoppingList: string, menuItem: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deleteMenuItemFromShoppingList(shoppingList, menuItem, requestId);
        });
    }
    public addPartyToShoppingList(shoppingList: string, party: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_addPartyToShoppingList(shoppingList, party, requestId);
        });
    }
    public deletePartyFromShoppingList(shoppingList: string, party: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deletePartyFromShoppingList(shoppingList, party, requestId);
        });
    }
    public addCellarItemToShoppingList(shoppingList: string, cellarItem: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_addCellarItemToShoppingList(shoppingList, cellarItem, requestId);
        });
    }
    public deleteCellarItemFromShoppingList(shoppingList: string, cellarItem: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deleteCellarItemFromShoppingList(shoppingList, cellarItem, requestId);
        });
    }
    public upsertManualItemOnShoppingList(shoppingListId: string, item: ManualItem.ManualItem): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_upsertManualItemOnShoppingList(shoppingListId, item, requestId);
        });
    }
    public deleteManualItemFromShoppingList(shoppingListId: string, itemId: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deleteManualItemFromShoppingList(shoppingListId, itemId, requestId);
        });
    }
    public getCellar(): Promise<Cellar.Cellar> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Cellar.Cellar>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_getCellar(requestId);
        });
    }
    public addCellarItems(item: CellarItem.CellarItem, howMany: number): Promise<Array<string>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<string>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_addCellarItems(item, howMany, requestId);
        });
    }
    public deleteCellarItem(id: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deleteCellarItem(id, requestId);
        });
    }
    public setCellarItemBroken(itemId: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_setCellarItemBroken(itemId, requestId);
        });
    }
    public unsetCellarItemBroken(itemId: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_unsetCellarItemBroken(itemId, requestId);
        });
    }
    public listParties(): Promise<Array<Party.Party>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<Party.Party>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_listParties(requestId);
        });
    }
    public upsertParty(party: Party.Party): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_upsertParty(party, requestId);
        });
    }
    public deleteParty(id: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deleteParty(id, requestId);
        });
    }
    public getParty(partyId: string): Promise<Party.Party> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Party.Party>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_getParty(partyId, requestId);
        });
    }
    public listPremenus(): Promise<Array<Premenu.Premenu>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<Premenu.Premenu>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_listPremenus(requestId);
        });
    }
    public getPremenu(premenuId: string): Promise<Premenu.Premenu> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Premenu.Premenu>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_getPremenu(premenuId, requestId);
        });
    }
    public deletePremenu(premenuId: string): Promise<void> {
        const requestId: number = this.incrementSeqId();
        return new Promise<void>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_deletePremenu(premenuId, requestId);
        });
    }
    public upsertPremenu(premenu: Premenu.Premenu): Promise<string> {
        const requestId: number = this.incrementSeqId();
        return new Promise<string>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_upsertPremenu(premenu, requestId);
        });
    }
    public usePremenu(premenuId: string, startDate: string, premenuStartDate: string, daysNum: number, factor: number): Promise<Array<string>> {
        const requestId: number = this.incrementSeqId();
        return new Promise<Array<string>>((resolve, reject): void => {
            this._reqs[requestId] = (error, result) => {
                delete this._reqs[requestId];
                if (error != null) {
                    reject(error);
                }
                else {
                    resolve(result);
                }
            };
            this.send_usePremenu(premenuId, startDate, premenuStartDate, daysNum, factor, requestId);
        });
    }
    public send_ping(requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("ping", thrift.Thrift.MessageType.CALL, requestId);
        const args: PingArgs = new PingArgs();
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_listEditable(type: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("listEditable", thrift.Thrift.MessageType.CALL, requestId);
        const args: ListEditableArgs = new ListEditableArgs({ type });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_listIngredients(requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("listIngredients", thrift.Thrift.MessageType.CALL, requestId);
        const args: ListIngredientsArgs = new ListIngredientsArgs();
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_getAccount(requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("getAccount", thrift.Thrift.MessageType.CALL, requestId);
        const args: GetAccountArgs = new GetAccountArgs();
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_upsertProfile(profile: Profile.Profile, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("upsertProfile", thrift.Thrift.MessageType.CALL, requestId);
        const args: UpsertProfileArgs = new UpsertProfileArgs({ profile });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_removeProfileFromGroup(profileId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("removeProfileFromGroup", thrift.Thrift.MessageType.CALL, requestId);
        const args: RemoveProfileFromGroupArgs = new RemoveProfileFromGroupArgs({ profileId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_getProfilePageInfo(id: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("getProfilePageInfo", thrift.Thrift.MessageType.CALL, requestId);
        const args: GetProfilePageInfoArgs = new GetProfilePageInfoArgs({ id });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_listRecipes(requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("listRecipes", thrift.Thrift.MessageType.CALL, requestId);
        const args: ListRecipesArgs = new ListRecipesArgs();
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_getRecipe(recipeId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("getRecipe", thrift.Thrift.MessageType.CALL, requestId);
        const args: GetRecipeArgs = new GetRecipeArgs({ recipeId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_upsertRecipe(recipe: Recipe.Recipe, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("upsertRecipe", thrift.Thrift.MessageType.CALL, requestId);
        const args: UpsertRecipeArgs = new UpsertRecipeArgs({ recipe });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deleteRecipe(recipeId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deleteRecipe", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeleteRecipeArgs = new DeleteRecipeArgs({ recipeId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_listMenuItems(owner: Owner.Owner, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("listMenuItems", thrift.Thrift.MessageType.CALL, requestId);
        const args: ListMenuItemsArgs = new ListMenuItemsArgs({ owner });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_listMenuItemsByIds(menuItemIds: Array<string>, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("listMenuItemsByIds", thrift.Thrift.MessageType.CALL, requestId);
        const args: ListMenuItemsByIdsArgs = new ListMenuItemsByIdsArgs({ menuItemIds });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_upsertMenuItem(item: MenuItem.MenuItem, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("upsertMenuItem", thrift.Thrift.MessageType.CALL, requestId);
        const args: UpsertMenuItemArgs = new UpsertMenuItemArgs({ item });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deleteMenuItem(id: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deleteMenuItem", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeleteMenuItemArgs = new DeleteMenuItemArgs({ id });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_listShoppingLists(owner: Owner.Owner, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("listShoppingLists", thrift.Thrift.MessageType.CALL, requestId);
        const args: ListShoppingListsArgs = new ListShoppingListsArgs({ owner });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_getShoppingList(id: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("getShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: GetShoppingListArgs = new GetShoppingListArgs({ id });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_createShoppingList(requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("createShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: CreateShoppingListArgs = new CreateShoppingListArgs();
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_createShoppingListFromMenuItems(daysBack: number, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("createShoppingListFromMenuItems", thrift.Thrift.MessageType.CALL, requestId);
        const args: CreateShoppingListFromMenuItemsArgs = new CreateShoppingListFromMenuItemsArgs({ daysBack });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deleteShoppingList(shoppingList: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deleteShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeleteShoppingListArgs = new DeleteShoppingListArgs({ shoppingList });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_setShoppingListStatus(shoppingList: string, status: ShoppingListStatus.ShoppingListStatus, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("setShoppingListStatus", thrift.Thrift.MessageType.CALL, requestId);
        const args: SetShoppingListStatusArgs = new SetShoppingListStatusArgs({ shoppingList, status });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_setPurchasedStatusOnShoppingList(shoppingListId: string, recipeIngredientId: string, status: boolean, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("setPurchasedStatusOnShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: SetPurchasedStatusOnShoppingListArgs = new SetPurchasedStatusOnShoppingListArgs({ shoppingListId, recipeIngredientId, status });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_addMenuItemToShoppingList(shoppingList: string, menuItem: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("addMenuItemToShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: AddMenuItemToShoppingListArgs = new AddMenuItemToShoppingListArgs({ shoppingList, menuItem });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deleteMenuItemFromShoppingList(shoppingList: string, menuItem: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deleteMenuItemFromShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeleteMenuItemFromShoppingListArgs = new DeleteMenuItemFromShoppingListArgs({ shoppingList, menuItem });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_addPartyToShoppingList(shoppingList: string, party: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("addPartyToShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: AddPartyToShoppingListArgs = new AddPartyToShoppingListArgs({ shoppingList, party });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deletePartyFromShoppingList(shoppingList: string, party: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deletePartyFromShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeletePartyFromShoppingListArgs = new DeletePartyFromShoppingListArgs({ shoppingList, party });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_addCellarItemToShoppingList(shoppingList: string, cellarItem: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("addCellarItemToShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: AddCellarItemToShoppingListArgs = new AddCellarItemToShoppingListArgs({ shoppingList, cellarItem });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deleteCellarItemFromShoppingList(shoppingList: string, cellarItem: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deleteCellarItemFromShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeleteCellarItemFromShoppingListArgs = new DeleteCellarItemFromShoppingListArgs({ shoppingList, cellarItem });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_upsertManualItemOnShoppingList(shoppingListId: string, item: ManualItem.ManualItem, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("upsertManualItemOnShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: UpsertManualItemOnShoppingListArgs = new UpsertManualItemOnShoppingListArgs({ shoppingListId, item });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deleteManualItemFromShoppingList(shoppingListId: string, itemId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deleteManualItemFromShoppingList", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeleteManualItemFromShoppingListArgs = new DeleteManualItemFromShoppingListArgs({ shoppingListId, itemId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_getCellar(requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("getCellar", thrift.Thrift.MessageType.CALL, requestId);
        const args: GetCellarArgs = new GetCellarArgs();
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_addCellarItems(item: CellarItem.CellarItem, howMany: number, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("addCellarItems", thrift.Thrift.MessageType.CALL, requestId);
        const args: AddCellarItemsArgs = new AddCellarItemsArgs({ item, howMany });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deleteCellarItem(id: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deleteCellarItem", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeleteCellarItemArgs = new DeleteCellarItemArgs({ id });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_setCellarItemBroken(itemId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("setCellarItemBroken", thrift.Thrift.MessageType.CALL, requestId);
        const args: SetCellarItemBrokenArgs = new SetCellarItemBrokenArgs({ itemId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_unsetCellarItemBroken(itemId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("unsetCellarItemBroken", thrift.Thrift.MessageType.CALL, requestId);
        const args: UnsetCellarItemBrokenArgs = new UnsetCellarItemBrokenArgs({ itemId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_listParties(requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("listParties", thrift.Thrift.MessageType.CALL, requestId);
        const args: ListPartiesArgs = new ListPartiesArgs();
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_upsertParty(party: Party.Party, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("upsertParty", thrift.Thrift.MessageType.CALL, requestId);
        const args: UpsertPartyArgs = new UpsertPartyArgs({ party });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deleteParty(id: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deleteParty", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeletePartyArgs = new DeletePartyArgs({ id });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_getParty(partyId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("getParty", thrift.Thrift.MessageType.CALL, requestId);
        const args: GetPartyArgs = new GetPartyArgs({ partyId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_listPremenus(requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("listPremenus", thrift.Thrift.MessageType.CALL, requestId);
        const args: ListPremenusArgs = new ListPremenusArgs();
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_getPremenu(premenuId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("getPremenu", thrift.Thrift.MessageType.CALL, requestId);
        const args: GetPremenuArgs = new GetPremenuArgs({ premenuId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_deletePremenu(premenuId: string, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("deletePremenu", thrift.Thrift.MessageType.CALL, requestId);
        const args: DeletePremenuArgs = new DeletePremenuArgs({ premenuId });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_upsertPremenu(premenu: Premenu.Premenu, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("upsertPremenu", thrift.Thrift.MessageType.CALL, requestId);
        const args: UpsertPremenuArgs = new UpsertPremenuArgs({ premenu });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public send_usePremenu(premenuId: string, startDate: string, premenuStartDate: string, daysNum: number, factor: number, requestId: number): void {
        const output: thrift.TProtocol = new this.protocol(this.output);
        output.writeMessageBegin("usePremenu", thrift.Thrift.MessageType.CALL, requestId);
        const args: UsePremenuArgs = new UsePremenuArgs({ premenuId, startDate, premenuStartDate, daysNum, factor });
        args.write(output);
        output.writeMessageEnd();
        this.output.flush();
        return;
    }
    public recv_ping(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: PingResult = PingResult.read(input);
            input.readMessageEnd();
            if (result.success != null) {
                return callback(undefined, result.success);
            }
            else {
                return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "ping failed: unknown result"));
            }
        }
    }
    public recv_listEditable(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: ListEditableResult = ListEditableResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "listEditable failed: unknown result"));
                }
            }
        }
    }
    public recv_listIngredients(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: ListIngredientsResult = ListIngredientsResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "listIngredients failed: unknown result"));
                }
            }
        }
    }
    public recv_getAccount(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: GetAccountResult = GetAccountResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getAccount failed: unknown result"));
                }
            }
        }
    }
    public recv_upsertProfile(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: UpsertProfileResult = UpsertProfileResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "upsertProfile failed: unknown result"));
                }
            }
        }
    }
    public recv_removeProfileFromGroup(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: RemoveProfileFromGroupResult = RemoveProfileFromGroupResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_getProfilePageInfo(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: GetProfilePageInfoResult = GetProfilePageInfoResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getProfilePageInfo failed: unknown result"));
                }
            }
        }
    }
    public recv_listRecipes(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: ListRecipesResult = ListRecipesResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "listRecipes failed: unknown result"));
                }
            }
        }
    }
    public recv_getRecipe(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: GetRecipeResult = GetRecipeResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getRecipe failed: unknown result"));
                }
            }
        }
    }
    public recv_upsertRecipe(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: UpsertRecipeResult = UpsertRecipeResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "upsertRecipe failed: unknown result"));
                }
            }
        }
    }
    public recv_deleteRecipe(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeleteRecipeResult = DeleteRecipeResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_listMenuItems(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: ListMenuItemsResult = ListMenuItemsResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "listMenuItems failed: unknown result"));
                }
            }
        }
    }
    public recv_listMenuItemsByIds(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: ListMenuItemsByIdsResult = ListMenuItemsByIdsResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "listMenuItemsByIds failed: unknown result"));
                }
            }
        }
    }
    public recv_upsertMenuItem(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: UpsertMenuItemResult = UpsertMenuItemResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "upsertMenuItem failed: unknown result"));
                }
            }
        }
    }
    public recv_deleteMenuItem(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeleteMenuItemResult = DeleteMenuItemResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_listShoppingLists(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: ListShoppingListsResult = ListShoppingListsResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "listShoppingLists failed: unknown result"));
                }
            }
        }
    }
    public recv_getShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: GetShoppingListResult = GetShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getShoppingList failed: unknown result"));
                }
            }
        }
    }
    public recv_createShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: CreateShoppingListResult = CreateShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "createShoppingList failed: unknown result"));
                }
            }
        }
    }
    public recv_createShoppingListFromMenuItems(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: CreateShoppingListFromMenuItemsResult = CreateShoppingListFromMenuItemsResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "createShoppingListFromMenuItems failed: unknown result"));
                }
            }
        }
    }
    public recv_deleteShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeleteShoppingListResult = DeleteShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_setShoppingListStatus(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: SetShoppingListStatusResult = SetShoppingListStatusResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_setPurchasedStatusOnShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: SetPurchasedStatusOnShoppingListResult = SetPurchasedStatusOnShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_addMenuItemToShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: AddMenuItemToShoppingListResult = AddMenuItemToShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_deleteMenuItemFromShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeleteMenuItemFromShoppingListResult = DeleteMenuItemFromShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_addPartyToShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: AddPartyToShoppingListResult = AddPartyToShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_deletePartyFromShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeletePartyFromShoppingListResult = DeletePartyFromShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_addCellarItemToShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: AddCellarItemToShoppingListResult = AddCellarItemToShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_deleteCellarItemFromShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeleteCellarItemFromShoppingListResult = DeleteCellarItemFromShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_upsertManualItemOnShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: UpsertManualItemOnShoppingListResult = UpsertManualItemOnShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "upsertManualItemOnShoppingList failed: unknown result"));
                }
            }
        }
    }
    public recv_deleteManualItemFromShoppingList(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeleteManualItemFromShoppingListResult = DeleteManualItemFromShoppingListResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_getCellar(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: GetCellarResult = GetCellarResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getCellar failed: unknown result"));
                }
            }
        }
    }
    public recv_addCellarItems(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: AddCellarItemsResult = AddCellarItemsResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "addCellarItems failed: unknown result"));
                }
            }
        }
    }
    public recv_deleteCellarItem(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeleteCellarItemResult = DeleteCellarItemResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_setCellarItemBroken(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: SetCellarItemBrokenResult = SetCellarItemBrokenResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_unsetCellarItemBroken(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: UnsetCellarItemBrokenResult = UnsetCellarItemBrokenResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_listParties(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: ListPartiesResult = ListPartiesResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "listParties failed: unknown result"));
                }
            }
        }
    }
    public recv_upsertParty(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: UpsertPartyResult = UpsertPartyResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "upsertParty failed: unknown result"));
                }
            }
        }
    }
    public recv_deleteParty(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeletePartyResult = DeletePartyResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_getParty(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: GetPartyResult = GetPartyResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getParty failed: unknown result"));
                }
            }
        }
    }
    public recv_listPremenus(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: ListPremenusResult = ListPremenusResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "listPremenus failed: unknown result"));
                }
            }
        }
    }
    public recv_getPremenu(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: GetPremenuResult = GetPremenuResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "getPremenu failed: unknown result"));
                }
            }
        }
    }
    public recv_deletePremenu(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: DeletePremenuResult = DeletePremenuResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                return callback(undefined);
            }
        }
    }
    public recv_upsertPremenu(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: UpsertPremenuResult = UpsertPremenuResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "upsertPremenu failed: unknown result"));
                }
            }
        }
    }
    public recv_usePremenu(input: thrift.TProtocol, mtype: thrift.Thrift.MessageType, requestId: number): void {
        const noop = (): any => null;
        const callback = this._reqs[requestId] || noop;
        if (mtype === thrift.Thrift.MessageType.EXCEPTION) {
            const x: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException();
            x.read(input);
            input.readMessageEnd();
            return callback(x);
        }
        else {
            const result: UsePremenuResult = UsePremenuResult.read(input);
            input.readMessageEnd();
            if (result.ce != null) {
                return callback(result.ce);
            }
            else {
                if (result.success != null) {
                    return callback(undefined, result.success);
                }
                else {
                    return callback(new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, "usePremenu failed: unknown result"));
                }
            }
        }
    }
}
export interface IHandler {
    ping(): string | Promise<string>;
    listEditable(type: string): Array<SR.SR> | Promise<Array<SR.SR>>;
    listIngredients(): Array<Ingredient.Ingredient> | Promise<Array<Ingredient.Ingredient>>;
    getAccount(): Account.Account | Promise<Account.Account>;
    upsertProfile(profile: Profile.Profile): string | Promise<string>;
    removeProfileFromGroup(profileId: string): void | Promise<void>;
    getProfilePageInfo(id: string): ProfilePageInfo.ProfilePageInfo | Promise<ProfilePageInfo.ProfilePageInfo>;
    listRecipes(): Array<Recipe.Recipe> | Promise<Array<Recipe.Recipe>>;
    getRecipe(recipeId: string): Recipe.Recipe | Promise<Recipe.Recipe>;
    upsertRecipe(recipe: Recipe.Recipe): string | Promise<string>;
    deleteRecipe(recipeId: string): void | Promise<void>;
    listMenuItems(owner: Owner.Owner): Array<MenuItem.MenuItem> | Promise<Array<MenuItem.MenuItem>>;
    listMenuItemsByIds(menuItemIds: Array<string>): Array<MenuItem.MenuItem> | Promise<Array<MenuItem.MenuItem>>;
    upsertMenuItem(item: MenuItem.MenuItem): string | Promise<string>;
    deleteMenuItem(id: string): void | Promise<void>;
    listShoppingLists(owner: Owner.Owner): Array<ShoppingList.ShoppingList> | Promise<Array<ShoppingList.ShoppingList>>;
    getShoppingList(id: string): ShoppingList.ShoppingList | Promise<ShoppingList.ShoppingList>;
    createShoppingList(): string | Promise<string>;
    createShoppingListFromMenuItems(daysBack: number): string | Promise<string>;
    deleteShoppingList(shoppingList: string): void | Promise<void>;
    setShoppingListStatus(shoppingList: string, status: ShoppingListStatus.ShoppingListStatus): void | Promise<void>;
    setPurchasedStatusOnShoppingList(shoppingListId: string, recipeIngredientId: string, status: boolean): void | Promise<void>;
    addMenuItemToShoppingList(shoppingList: string, menuItem: string): void | Promise<void>;
    deleteMenuItemFromShoppingList(shoppingList: string, menuItem: string): void | Promise<void>;
    addPartyToShoppingList(shoppingList: string, party: string): void | Promise<void>;
    deletePartyFromShoppingList(shoppingList: string, party: string): void | Promise<void>;
    addCellarItemToShoppingList(shoppingList: string, cellarItem: string): void | Promise<void>;
    deleteCellarItemFromShoppingList(shoppingList: string, cellarItem: string): void | Promise<void>;
    upsertManualItemOnShoppingList(shoppingListId: string, item: ManualItem.ManualItem): string | Promise<string>;
    deleteManualItemFromShoppingList(shoppingListId: string, itemId: string): void | Promise<void>;
    getCellar(): Cellar.Cellar | Promise<Cellar.Cellar>;
    addCellarItems(item: CellarItem.CellarItem, howMany: number): Array<string> | Promise<Array<string>>;
    deleteCellarItem(id: string): void | Promise<void>;
    setCellarItemBroken(itemId: string): void | Promise<void>;
    unsetCellarItemBroken(itemId: string): void | Promise<void>;
    listParties(): Array<Party.Party> | Promise<Array<Party.Party>>;
    upsertParty(party: Party.Party): string | Promise<string>;
    deleteParty(id: string): void | Promise<void>;
    getParty(partyId: string): Party.Party | Promise<Party.Party>;
    listPremenus(): Array<Premenu.Premenu> | Promise<Array<Premenu.Premenu>>;
    getPremenu(premenuId: string): Premenu.Premenu | Promise<Premenu.Premenu>;
    deletePremenu(premenuId: string): void | Promise<void>;
    upsertPremenu(premenu: Premenu.Premenu): string | Promise<string>;
    usePremenu(premenuId: string, startDate: string, premenuStartDate: string, daysNum: number, factor: number): Array<string> | Promise<Array<string>>;
}
export class Processor {
    public _handler: IHandler;
    constructor(handler: IHandler) {
        this._handler = handler;
    }
    public process(input: thrift.TProtocol, output: thrift.TProtocol): void {
        const metadata: thrift.TMessage = input.readMessageBegin();
        const fname: string = metadata.fname;
        const requestId: number = metadata.rseqid;
        const methodName: string = "process_" + fname;
        switch (methodName) {
            case "process_ping": {
                this.process_ping(requestId, input, output);
                return;
            }
            case "process_listEditable": {
                this.process_listEditable(requestId, input, output);
                return;
            }
            case "process_listIngredients": {
                this.process_listIngredients(requestId, input, output);
                return;
            }
            case "process_getAccount": {
                this.process_getAccount(requestId, input, output);
                return;
            }
            case "process_upsertProfile": {
                this.process_upsertProfile(requestId, input, output);
                return;
            }
            case "process_removeProfileFromGroup": {
                this.process_removeProfileFromGroup(requestId, input, output);
                return;
            }
            case "process_getProfilePageInfo": {
                this.process_getProfilePageInfo(requestId, input, output);
                return;
            }
            case "process_listRecipes": {
                this.process_listRecipes(requestId, input, output);
                return;
            }
            case "process_getRecipe": {
                this.process_getRecipe(requestId, input, output);
                return;
            }
            case "process_upsertRecipe": {
                this.process_upsertRecipe(requestId, input, output);
                return;
            }
            case "process_deleteRecipe": {
                this.process_deleteRecipe(requestId, input, output);
                return;
            }
            case "process_listMenuItems": {
                this.process_listMenuItems(requestId, input, output);
                return;
            }
            case "process_listMenuItemsByIds": {
                this.process_listMenuItemsByIds(requestId, input, output);
                return;
            }
            case "process_upsertMenuItem": {
                this.process_upsertMenuItem(requestId, input, output);
                return;
            }
            case "process_deleteMenuItem": {
                this.process_deleteMenuItem(requestId, input, output);
                return;
            }
            case "process_listShoppingLists": {
                this.process_listShoppingLists(requestId, input, output);
                return;
            }
            case "process_getShoppingList": {
                this.process_getShoppingList(requestId, input, output);
                return;
            }
            case "process_createShoppingList": {
                this.process_createShoppingList(requestId, input, output);
                return;
            }
            case "process_createShoppingListFromMenuItems": {
                this.process_createShoppingListFromMenuItems(requestId, input, output);
                return;
            }
            case "process_deleteShoppingList": {
                this.process_deleteShoppingList(requestId, input, output);
                return;
            }
            case "process_setShoppingListStatus": {
                this.process_setShoppingListStatus(requestId, input, output);
                return;
            }
            case "process_setPurchasedStatusOnShoppingList": {
                this.process_setPurchasedStatusOnShoppingList(requestId, input, output);
                return;
            }
            case "process_addMenuItemToShoppingList": {
                this.process_addMenuItemToShoppingList(requestId, input, output);
                return;
            }
            case "process_deleteMenuItemFromShoppingList": {
                this.process_deleteMenuItemFromShoppingList(requestId, input, output);
                return;
            }
            case "process_addPartyToShoppingList": {
                this.process_addPartyToShoppingList(requestId, input, output);
                return;
            }
            case "process_deletePartyFromShoppingList": {
                this.process_deletePartyFromShoppingList(requestId, input, output);
                return;
            }
            case "process_addCellarItemToShoppingList": {
                this.process_addCellarItemToShoppingList(requestId, input, output);
                return;
            }
            case "process_deleteCellarItemFromShoppingList": {
                this.process_deleteCellarItemFromShoppingList(requestId, input, output);
                return;
            }
            case "process_upsertManualItemOnShoppingList": {
                this.process_upsertManualItemOnShoppingList(requestId, input, output);
                return;
            }
            case "process_deleteManualItemFromShoppingList": {
                this.process_deleteManualItemFromShoppingList(requestId, input, output);
                return;
            }
            case "process_getCellar": {
                this.process_getCellar(requestId, input, output);
                return;
            }
            case "process_addCellarItems": {
                this.process_addCellarItems(requestId, input, output);
                return;
            }
            case "process_deleteCellarItem": {
                this.process_deleteCellarItem(requestId, input, output);
                return;
            }
            case "process_setCellarItemBroken": {
                this.process_setCellarItemBroken(requestId, input, output);
                return;
            }
            case "process_unsetCellarItemBroken": {
                this.process_unsetCellarItemBroken(requestId, input, output);
                return;
            }
            case "process_listParties": {
                this.process_listParties(requestId, input, output);
                return;
            }
            case "process_upsertParty": {
                this.process_upsertParty(requestId, input, output);
                return;
            }
            case "process_deleteParty": {
                this.process_deleteParty(requestId, input, output);
                return;
            }
            case "process_getParty": {
                this.process_getParty(requestId, input, output);
                return;
            }
            case "process_listPremenus": {
                this.process_listPremenus(requestId, input, output);
                return;
            }
            case "process_getPremenu": {
                this.process_getPremenu(requestId, input, output);
                return;
            }
            case "process_deletePremenu": {
                this.process_deletePremenu(requestId, input, output);
                return;
            }
            case "process_upsertPremenu": {
                this.process_upsertPremenu(requestId, input, output);
                return;
            }
            case "process_usePremenu": {
                this.process_usePremenu(requestId, input, output);
                return;
            }
            default: {
                input.skip(thrift.Thrift.Type.STRUCT);
                input.readMessageEnd();
                const errMessage = "Unknown function " + fname;
                const err = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN_METHOD, errMessage);
                output.writeMessageBegin(fname, thrift.Thrift.MessageType.EXCEPTION, requestId);
                err.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        }
    }
    public process_ping(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                input.readMessageEnd();
                resolve(this._handler.ping());
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: PingResult = new PingResult({ success: data });
            output.writeMessageBegin("ping", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
            output.writeMessageBegin("ping", thrift.Thrift.MessageType.EXCEPTION, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        });
    }
    public process_listEditable(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<SR.SR>>((resolve, reject): void => {
            try {
                const args: ListEditableArgs = ListEditableArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.listEditable(args.type));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<SR.SR>): void => {
            const result: ListEditableResult = new ListEditableResult({ success: data });
            output.writeMessageBegin("listEditable", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: ListEditableResult = new ListEditableResult({ ce: err });
                output.writeMessageBegin("listEditable", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listEditable", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_listIngredients(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<Ingredient.Ingredient>>((resolve, reject): void => {
            try {
                input.readMessageEnd();
                resolve(this._handler.listIngredients());
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<Ingredient.Ingredient>): void => {
            const result: ListIngredientsResult = new ListIngredientsResult({ success: data });
            output.writeMessageBegin("listIngredients", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: ListIngredientsResult = new ListIngredientsResult({ ce: err });
                output.writeMessageBegin("listIngredients", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listIngredients", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_getAccount(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Account.Account>((resolve, reject): void => {
            try {
                input.readMessageEnd();
                resolve(this._handler.getAccount());
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Account.Account): void => {
            const result: GetAccountResult = new GetAccountResult({ success: data });
            output.writeMessageBegin("getAccount", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: GetAccountResult = new GetAccountResult({ ce: err });
                output.writeMessageBegin("getAccount", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("getAccount", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_upsertProfile(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                const args: UpsertProfileArgs = UpsertProfileArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.upsertProfile(args.profile));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: UpsertProfileResult = new UpsertProfileResult({ success: data });
            output.writeMessageBegin("upsertProfile", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: UpsertProfileResult = new UpsertProfileResult({ ce: err });
                output.writeMessageBegin("upsertProfile", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("upsertProfile", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_removeProfileFromGroup(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: RemoveProfileFromGroupArgs = RemoveProfileFromGroupArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.removeProfileFromGroup(args.profileId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: RemoveProfileFromGroupResult = new RemoveProfileFromGroupResult({ success: data });
            output.writeMessageBegin("removeProfileFromGroup", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: RemoveProfileFromGroupResult = new RemoveProfileFromGroupResult({ ce: err });
                output.writeMessageBegin("removeProfileFromGroup", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("removeProfileFromGroup", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_getProfilePageInfo(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<ProfilePageInfo.ProfilePageInfo>((resolve, reject): void => {
            try {
                const args: GetProfilePageInfoArgs = GetProfilePageInfoArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.getProfilePageInfo(args.id));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: ProfilePageInfo.ProfilePageInfo): void => {
            const result: GetProfilePageInfoResult = new GetProfilePageInfoResult({ success: data });
            output.writeMessageBegin("getProfilePageInfo", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: GetProfilePageInfoResult = new GetProfilePageInfoResult({ ce: err });
                output.writeMessageBegin("getProfilePageInfo", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("getProfilePageInfo", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_listRecipes(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<Recipe.Recipe>>((resolve, reject): void => {
            try {
                input.readMessageEnd();
                resolve(this._handler.listRecipes());
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<Recipe.Recipe>): void => {
            const result: ListRecipesResult = new ListRecipesResult({ success: data });
            output.writeMessageBegin("listRecipes", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: ListRecipesResult = new ListRecipesResult({ ce: err });
                output.writeMessageBegin("listRecipes", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listRecipes", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_getRecipe(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Recipe.Recipe>((resolve, reject): void => {
            try {
                const args: GetRecipeArgs = GetRecipeArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.getRecipe(args.recipeId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Recipe.Recipe): void => {
            const result: GetRecipeResult = new GetRecipeResult({ success: data });
            output.writeMessageBegin("getRecipe", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: GetRecipeResult = new GetRecipeResult({ ce: err });
                output.writeMessageBegin("getRecipe", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("getRecipe", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_upsertRecipe(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                const args: UpsertRecipeArgs = UpsertRecipeArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.upsertRecipe(args.recipe));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: UpsertRecipeResult = new UpsertRecipeResult({ success: data });
            output.writeMessageBegin("upsertRecipe", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: UpsertRecipeResult = new UpsertRecipeResult({ ce: err });
                output.writeMessageBegin("upsertRecipe", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("upsertRecipe", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deleteRecipe(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeleteRecipeArgs = DeleteRecipeArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deleteRecipe(args.recipeId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeleteRecipeResult = new DeleteRecipeResult({ success: data });
            output.writeMessageBegin("deleteRecipe", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeleteRecipeResult = new DeleteRecipeResult({ ce: err });
                output.writeMessageBegin("deleteRecipe", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deleteRecipe", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_listMenuItems(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<MenuItem.MenuItem>>((resolve, reject): void => {
            try {
                const args: ListMenuItemsArgs = ListMenuItemsArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.listMenuItems(args.owner));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<MenuItem.MenuItem>): void => {
            const result: ListMenuItemsResult = new ListMenuItemsResult({ success: data });
            output.writeMessageBegin("listMenuItems", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: ListMenuItemsResult = new ListMenuItemsResult({ ce: err });
                output.writeMessageBegin("listMenuItems", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listMenuItems", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_listMenuItemsByIds(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<MenuItem.MenuItem>>((resolve, reject): void => {
            try {
                const args: ListMenuItemsByIdsArgs = ListMenuItemsByIdsArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.listMenuItemsByIds(args.menuItemIds));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<MenuItem.MenuItem>): void => {
            const result: ListMenuItemsByIdsResult = new ListMenuItemsByIdsResult({ success: data });
            output.writeMessageBegin("listMenuItemsByIds", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: ListMenuItemsByIdsResult = new ListMenuItemsByIdsResult({ ce: err });
                output.writeMessageBegin("listMenuItemsByIds", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listMenuItemsByIds", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_upsertMenuItem(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                const args: UpsertMenuItemArgs = UpsertMenuItemArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.upsertMenuItem(args.item));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: UpsertMenuItemResult = new UpsertMenuItemResult({ success: data });
            output.writeMessageBegin("upsertMenuItem", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: UpsertMenuItemResult = new UpsertMenuItemResult({ ce: err });
                output.writeMessageBegin("upsertMenuItem", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("upsertMenuItem", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deleteMenuItem(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeleteMenuItemArgs = DeleteMenuItemArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deleteMenuItem(args.id));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeleteMenuItemResult = new DeleteMenuItemResult({ success: data });
            output.writeMessageBegin("deleteMenuItem", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeleteMenuItemResult = new DeleteMenuItemResult({ ce: err });
                output.writeMessageBegin("deleteMenuItem", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deleteMenuItem", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_listShoppingLists(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<ShoppingList.ShoppingList>>((resolve, reject): void => {
            try {
                const args: ListShoppingListsArgs = ListShoppingListsArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.listShoppingLists(args.owner));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<ShoppingList.ShoppingList>): void => {
            const result: ListShoppingListsResult = new ListShoppingListsResult({ success: data });
            output.writeMessageBegin("listShoppingLists", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: ListShoppingListsResult = new ListShoppingListsResult({ ce: err });
                output.writeMessageBegin("listShoppingLists", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listShoppingLists", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_getShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<ShoppingList.ShoppingList>((resolve, reject): void => {
            try {
                const args: GetShoppingListArgs = GetShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.getShoppingList(args.id));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: ShoppingList.ShoppingList): void => {
            const result: GetShoppingListResult = new GetShoppingListResult({ success: data });
            output.writeMessageBegin("getShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: GetShoppingListResult = new GetShoppingListResult({ ce: err });
                output.writeMessageBegin("getShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("getShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_createShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                input.readMessageEnd();
                resolve(this._handler.createShoppingList());
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: CreateShoppingListResult = new CreateShoppingListResult({ success: data });
            output.writeMessageBegin("createShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: CreateShoppingListResult = new CreateShoppingListResult({ ce: err });
                output.writeMessageBegin("createShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("createShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_createShoppingListFromMenuItems(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                const args: CreateShoppingListFromMenuItemsArgs = CreateShoppingListFromMenuItemsArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.createShoppingListFromMenuItems(args.daysBack));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: CreateShoppingListFromMenuItemsResult = new CreateShoppingListFromMenuItemsResult({ success: data });
            output.writeMessageBegin("createShoppingListFromMenuItems", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: CreateShoppingListFromMenuItemsResult = new CreateShoppingListFromMenuItemsResult({ ce: err });
                output.writeMessageBegin("createShoppingListFromMenuItems", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("createShoppingListFromMenuItems", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deleteShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeleteShoppingListArgs = DeleteShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deleteShoppingList(args.shoppingList));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeleteShoppingListResult = new DeleteShoppingListResult({ success: data });
            output.writeMessageBegin("deleteShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeleteShoppingListResult = new DeleteShoppingListResult({ ce: err });
                output.writeMessageBegin("deleteShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deleteShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_setShoppingListStatus(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: SetShoppingListStatusArgs = SetShoppingListStatusArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.setShoppingListStatus(args.shoppingList, args.status));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: SetShoppingListStatusResult = new SetShoppingListStatusResult({ success: data });
            output.writeMessageBegin("setShoppingListStatus", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: SetShoppingListStatusResult = new SetShoppingListStatusResult({ ce: err });
                output.writeMessageBegin("setShoppingListStatus", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("setShoppingListStatus", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_setPurchasedStatusOnShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: SetPurchasedStatusOnShoppingListArgs = SetPurchasedStatusOnShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.setPurchasedStatusOnShoppingList(args.shoppingListId, args.recipeIngredientId, args.status));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: SetPurchasedStatusOnShoppingListResult = new SetPurchasedStatusOnShoppingListResult({ success: data });
            output.writeMessageBegin("setPurchasedStatusOnShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: SetPurchasedStatusOnShoppingListResult = new SetPurchasedStatusOnShoppingListResult({ ce: err });
                output.writeMessageBegin("setPurchasedStatusOnShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("setPurchasedStatusOnShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_addMenuItemToShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: AddMenuItemToShoppingListArgs = AddMenuItemToShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.addMenuItemToShoppingList(args.shoppingList, args.menuItem));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: AddMenuItemToShoppingListResult = new AddMenuItemToShoppingListResult({ success: data });
            output.writeMessageBegin("addMenuItemToShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: AddMenuItemToShoppingListResult = new AddMenuItemToShoppingListResult({ ce: err });
                output.writeMessageBegin("addMenuItemToShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("addMenuItemToShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deleteMenuItemFromShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeleteMenuItemFromShoppingListArgs = DeleteMenuItemFromShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deleteMenuItemFromShoppingList(args.shoppingList, args.menuItem));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeleteMenuItemFromShoppingListResult = new DeleteMenuItemFromShoppingListResult({ success: data });
            output.writeMessageBegin("deleteMenuItemFromShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeleteMenuItemFromShoppingListResult = new DeleteMenuItemFromShoppingListResult({ ce: err });
                output.writeMessageBegin("deleteMenuItemFromShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deleteMenuItemFromShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_addPartyToShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: AddPartyToShoppingListArgs = AddPartyToShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.addPartyToShoppingList(args.shoppingList, args.party));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: AddPartyToShoppingListResult = new AddPartyToShoppingListResult({ success: data });
            output.writeMessageBegin("addPartyToShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: AddPartyToShoppingListResult = new AddPartyToShoppingListResult({ ce: err });
                output.writeMessageBegin("addPartyToShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("addPartyToShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deletePartyFromShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeletePartyFromShoppingListArgs = DeletePartyFromShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deletePartyFromShoppingList(args.shoppingList, args.party));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeletePartyFromShoppingListResult = new DeletePartyFromShoppingListResult({ success: data });
            output.writeMessageBegin("deletePartyFromShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeletePartyFromShoppingListResult = new DeletePartyFromShoppingListResult({ ce: err });
                output.writeMessageBegin("deletePartyFromShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deletePartyFromShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_addCellarItemToShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: AddCellarItemToShoppingListArgs = AddCellarItemToShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.addCellarItemToShoppingList(args.shoppingList, args.cellarItem));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: AddCellarItemToShoppingListResult = new AddCellarItemToShoppingListResult({ success: data });
            output.writeMessageBegin("addCellarItemToShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: AddCellarItemToShoppingListResult = new AddCellarItemToShoppingListResult({ ce: err });
                output.writeMessageBegin("addCellarItemToShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("addCellarItemToShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deleteCellarItemFromShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeleteCellarItemFromShoppingListArgs = DeleteCellarItemFromShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deleteCellarItemFromShoppingList(args.shoppingList, args.cellarItem));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeleteCellarItemFromShoppingListResult = new DeleteCellarItemFromShoppingListResult({ success: data });
            output.writeMessageBegin("deleteCellarItemFromShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeleteCellarItemFromShoppingListResult = new DeleteCellarItemFromShoppingListResult({ ce: err });
                output.writeMessageBegin("deleteCellarItemFromShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deleteCellarItemFromShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_upsertManualItemOnShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                const args: UpsertManualItemOnShoppingListArgs = UpsertManualItemOnShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.upsertManualItemOnShoppingList(args.shoppingListId, args.item));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: UpsertManualItemOnShoppingListResult = new UpsertManualItemOnShoppingListResult({ success: data });
            output.writeMessageBegin("upsertManualItemOnShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: UpsertManualItemOnShoppingListResult = new UpsertManualItemOnShoppingListResult({ ce: err });
                output.writeMessageBegin("upsertManualItemOnShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("upsertManualItemOnShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deleteManualItemFromShoppingList(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeleteManualItemFromShoppingListArgs = DeleteManualItemFromShoppingListArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deleteManualItemFromShoppingList(args.shoppingListId, args.itemId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeleteManualItemFromShoppingListResult = new DeleteManualItemFromShoppingListResult({ success: data });
            output.writeMessageBegin("deleteManualItemFromShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeleteManualItemFromShoppingListResult = new DeleteManualItemFromShoppingListResult({ ce: err });
                output.writeMessageBegin("deleteManualItemFromShoppingList", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deleteManualItemFromShoppingList", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_getCellar(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Cellar.Cellar>((resolve, reject): void => {
            try {
                input.readMessageEnd();
                resolve(this._handler.getCellar());
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Cellar.Cellar): void => {
            const result: GetCellarResult = new GetCellarResult({ success: data });
            output.writeMessageBegin("getCellar", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: GetCellarResult = new GetCellarResult({ ce: err });
                output.writeMessageBegin("getCellar", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("getCellar", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_addCellarItems(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<string>>((resolve, reject): void => {
            try {
                const args: AddCellarItemsArgs = AddCellarItemsArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.addCellarItems(args.item, args.howMany));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<string>): void => {
            const result: AddCellarItemsResult = new AddCellarItemsResult({ success: data });
            output.writeMessageBegin("addCellarItems", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: AddCellarItemsResult = new AddCellarItemsResult({ ce: err });
                output.writeMessageBegin("addCellarItems", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("addCellarItems", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deleteCellarItem(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeleteCellarItemArgs = DeleteCellarItemArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deleteCellarItem(args.id));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeleteCellarItemResult = new DeleteCellarItemResult({ success: data });
            output.writeMessageBegin("deleteCellarItem", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeleteCellarItemResult = new DeleteCellarItemResult({ ce: err });
                output.writeMessageBegin("deleteCellarItem", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deleteCellarItem", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_setCellarItemBroken(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: SetCellarItemBrokenArgs = SetCellarItemBrokenArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.setCellarItemBroken(args.itemId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: SetCellarItemBrokenResult = new SetCellarItemBrokenResult({ success: data });
            output.writeMessageBegin("setCellarItemBroken", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: SetCellarItemBrokenResult = new SetCellarItemBrokenResult({ ce: err });
                output.writeMessageBegin("setCellarItemBroken", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("setCellarItemBroken", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_unsetCellarItemBroken(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: UnsetCellarItemBrokenArgs = UnsetCellarItemBrokenArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.unsetCellarItemBroken(args.itemId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: UnsetCellarItemBrokenResult = new UnsetCellarItemBrokenResult({ success: data });
            output.writeMessageBegin("unsetCellarItemBroken", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: UnsetCellarItemBrokenResult = new UnsetCellarItemBrokenResult({ ce: err });
                output.writeMessageBegin("unsetCellarItemBroken", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("unsetCellarItemBroken", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_listParties(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<Party.Party>>((resolve, reject): void => {
            try {
                input.readMessageEnd();
                resolve(this._handler.listParties());
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<Party.Party>): void => {
            const result: ListPartiesResult = new ListPartiesResult({ success: data });
            output.writeMessageBegin("listParties", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: ListPartiesResult = new ListPartiesResult({ ce: err });
                output.writeMessageBegin("listParties", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listParties", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_upsertParty(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                const args: UpsertPartyArgs = UpsertPartyArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.upsertParty(args.party));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: UpsertPartyResult = new UpsertPartyResult({ success: data });
            output.writeMessageBegin("upsertParty", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: UpsertPartyResult = new UpsertPartyResult({ ce: err });
                output.writeMessageBegin("upsertParty", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("upsertParty", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deleteParty(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeletePartyArgs = DeletePartyArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deleteParty(args.id));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeletePartyResult = new DeletePartyResult({ success: data });
            output.writeMessageBegin("deleteParty", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeletePartyResult = new DeletePartyResult({ ce: err });
                output.writeMessageBegin("deleteParty", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deleteParty", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_getParty(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Party.Party>((resolve, reject): void => {
            try {
                const args: GetPartyArgs = GetPartyArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.getParty(args.partyId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Party.Party): void => {
            const result: GetPartyResult = new GetPartyResult({ success: data });
            output.writeMessageBegin("getParty", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: GetPartyResult = new GetPartyResult({ ce: err });
                output.writeMessageBegin("getParty", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("getParty", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_listPremenus(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<Premenu.Premenu>>((resolve, reject): void => {
            try {
                input.readMessageEnd();
                resolve(this._handler.listPremenus());
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<Premenu.Premenu>): void => {
            const result: ListPremenusResult = new ListPremenusResult({ success: data });
            output.writeMessageBegin("listPremenus", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: ListPremenusResult = new ListPremenusResult({ ce: err });
                output.writeMessageBegin("listPremenus", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("listPremenus", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_getPremenu(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Premenu.Premenu>((resolve, reject): void => {
            try {
                const args: GetPremenuArgs = GetPremenuArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.getPremenu(args.premenuId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Premenu.Premenu): void => {
            const result: GetPremenuResult = new GetPremenuResult({ success: data });
            output.writeMessageBegin("getPremenu", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: GetPremenuResult = new GetPremenuResult({ ce: err });
                output.writeMessageBegin("getPremenu", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("getPremenu", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_deletePremenu(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<void>((resolve, reject): void => {
            try {
                const args: DeletePremenuArgs = DeletePremenuArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.deletePremenu(args.premenuId));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: void): void => {
            const result: DeletePremenuResult = new DeletePremenuResult({ success: data });
            output.writeMessageBegin("deletePremenu", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: DeletePremenuResult = new DeletePremenuResult({ ce: err });
                output.writeMessageBegin("deletePremenu", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("deletePremenu", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_upsertPremenu(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<string>((resolve, reject): void => {
            try {
                const args: UpsertPremenuArgs = UpsertPremenuArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.upsertPremenu(args.premenu));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: string): void => {
            const result: UpsertPremenuResult = new UpsertPremenuResult({ success: data });
            output.writeMessageBegin("upsertPremenu", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: UpsertPremenuResult = new UpsertPremenuResult({ ce: err });
                output.writeMessageBegin("upsertPremenu", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("upsertPremenu", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
    public process_usePremenu(requestId: number, input: thrift.TProtocol, output: thrift.TProtocol): void {
        new Promise<Array<string>>((resolve, reject): void => {
            try {
                const args: UsePremenuArgs = UsePremenuArgs.read(input);
                input.readMessageEnd();
                resolve(this._handler.usePremenu(args.premenuId, args.startDate, args.premenuStartDate, args.daysNum, args.factor));
            }
            catch (err) {
                reject(err);
            }
        }).then((data: Array<string>): void => {
            const result: UsePremenuResult = new UsePremenuResult({ success: data });
            output.writeMessageBegin("usePremenu", thrift.Thrift.MessageType.REPLY, requestId);
            result.write(output);
            output.writeMessageEnd();
            output.flush();
            return;
        }).catch((err: Error): void => {
            if (err instanceof CommonException.CommonException) {
                const result: UsePremenuResult = new UsePremenuResult({ ce: err });
                output.writeMessageBegin("usePremenu", thrift.Thrift.MessageType.REPLY, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
            else {
                const result: thrift.Thrift.TApplicationException = new thrift.Thrift.TApplicationException(thrift.Thrift.TApplicationExceptionType.UNKNOWN, err.message);
                output.writeMessageBegin("usePremenu", thrift.Thrift.MessageType.EXCEPTION, requestId);
                result.write(output);
                output.writeMessageEnd();
                output.flush();
                return;
            }
        });
    }
}
