import { StateObservable } from 'redux-observable';
import { concat, Observable, of, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import * as _ from 'underscore';

import { ActionTypes as CoreActionTypes, RevalidateCacheAction } from '@stack/frontend-core/cls/redux';

import Api from 'cls/Api';
import Cellar from 'cls/Cellar';
import Ingredient from 'cls/Ingredient';
import Menu from 'cls/Menu';
import MenuItem from 'cls/MenuItem';
import Messages from 'cls/Messages';
import { N } from 'cls/N';
import Party from 'cls/Party';
import Premenu from 'cls/Premenu';
import Recipe from 'cls/Recipe';
import {
  ActionTypes,
  AddCellarItemAction,
  AddCellarItemToShoppingListAction,
  AddMenuItemAction, AddMenuItemsToShoppingListAction,
  AddMenuItemToShoppingListAction,
  AddPartyToShoppingListAction,
  AllActions,
  CreateShoppingListAction, CreateShoppingListFromMenuItemsAction, DeleteAction,
  DeleteCellarItemAction,
  DeleteCellarItemFromShoppingListAction,
  DeleteManualItemFromShoppingListAction,
  DeleteMenuItemAction,
  DeleteMenuItemFromShoppingListAction,
  DeletePartyAction,
  DeletePartyFromShoppingListAction,
  DeleteShoppingListAction,
  GetPartyAction,
  GetPremenuAction,
  GetProfilePageAction,
  ListAllAction,
  ListCellarItemsAction,
  ListEditableAction,
  ListMenuItemsAction,
  ListMenuItemsUserAction,
  ListPartiesAction,
  ListPremenusAction,
  ListShoppingListAction,
  SetCellarItemBrokenAction,
  SetPurchasedStatusOnShoppingListAction,
  SetShoppingListStatusAction,
  StartPremenuMenuEditionAction,
  UnsetCellarItemBrokenAction,
  UpdateMenuItemAction,
  UpsertManualItemToShoppingListAction,
  UpsertPartyAction,
  UpsertPremenuAction,
  UpsertRecipeAction,
  UsePremenuAction,
} from 'cls/redux/actions';
import EpicType from 'cls/redux/epictype';
import { getEpic, ofType } from 'cls/redux/util';
import ShoppingList from 'cls/ShoppingList';
import { StoreState } from 'cls/store';
import Summary from 'cls/Summary';
import Utils from 'cls/Utils';
import { OwnerType, ProfilePageInfo, SR } from 'thriftgen';
import { ShoppingList as ThriftShoppingList } from 'thriftgen/ShoppingList';
import { ShoppingListStatus } from 'thriftgen/ShoppingListStatus';

export interface BasicState {
  ingredients: Ingredient[];
  recipes: Recipe[];
  editable: SR[] | null;
  n: N | null;
  plan: Menu | null;
  summary: Summary | null;
  cellar: Cellar | null;
  shoppingLists: ShoppingList[] | null;
  parties: Party[] | null;
  party: Party | null;
  premenus: Premenu[] | null;
  premenu: Premenu | null;
  editedPremenu: Premenu | null;
  noShoppingListMenuItems: MenuItem[];
  userMenus: { [key: string]: Menu };
  userNames: { [key: string]: string },
  flags: string[] | null;
}

const initBasic = {
  ingredients: [],
  recipes: [],
  editable: null,
  nutrition: null,
  n: null,
  party: null,
  plan: null,
  summary: null,
  cellar: null,
  shoppingLists: null,
  noShoppingListMenuItems: [],
  userMenus: {},
  userNames: {},
  parties: null,
  premenus: null,
  premenu: null,
  editedPremenu: null,
  flags: null,
};

export const basicReducer = (state: BasicState = initBasic, action: AllActions): BasicState => {
  switch (action.type) {
    case ActionTypes.AddMenuItemSuccess: {
      const { plan, editedPremenu, noShoppingListMenuItems } = state;
      if (plan === null && editedPremenu === null) { return state; }

      const mi = action.menuItem;
      mi.setIngredients(state.ingredients);
      mi.setRecipes(state.recipes);

      if (plan !== null && mi.ownerType === OwnerType.USER) {
        plan.addItem(mi);
        return {
          ...state,
          plan: plan.copy(),
          noShoppingListMenuItems: [ ...noShoppingListMenuItems, mi ],
        };
      }
      if (editedPremenu !== null && mi.ownerType === OwnerType.PREMENU) {
        const ep = editedPremenu.copy();
        ep.addItem(mi);
        return {
          ...state,
          editedPremenu: ep,
        }
      }
      return state;
    }
    case ActionTypes.DeleteMenuItemSuccess: {
      const { plan, shoppingLists, editedPremenu, premenu } = state;

      if (editedPremenu !== null) {
        const editedPremenuCopy = editedPremenu.copy();
        editedPremenuCopy.removeItem(action.itemId);
        const premenuCopy = premenu === null ? premenu : premenu.copy();
        if (premenuCopy !== null) {
          premenuCopy.removeItem(action.itemId);
        }
        return {
          ...state,
          editedPremenu: editedPremenuCopy,
          premenu: premenuCopy,
        };
      }

      const p = plan === null ? null : plan.copy();
      if (p !== null) {
        p.removeItem(action.itemId);
      }
      if (shoppingLists !== null) {
        shoppingLists.forEach((x) => x.removeItem(action.itemId));
      }
      return {
        ...state,
        plan: p,
        shoppingLists: shoppingLists === null ? null : [ ...shoppingLists ],
        noShoppingListMenuItems: state.noShoppingListMenuItems.filter((x) => x.id !== action.itemId),
      };
    }
    case ActionTypes.UpdateMenuItemSuccess: {
      const { plan, shoppingLists, noShoppingListMenuItems, editedPremenu } = state;
      const mi = action.menuItem.copy();

      if (editedPremenu !== null) {
        const ep = editedPremenu.copy();
        ep.updateItem(mi);
        return {
          ...state,
          editedPremenu: ep,
        }
      }

      if (plan === null && shoppingLists === null) { return state; }

      let p: Menu | null = null;
      if (plan !== null) {
        p = plan.copy();
        p.updateMenuItem(mi);
      }
      shoppingLists?.forEach((x) => x.updateItem(mi));
      return {
        ...state,
        shoppingLists: shoppingLists !== null ? [ ...shoppingLists ] : null,
        noShoppingListMenuItems: noShoppingListMenuItems.map((x) => x.id === mi.id ? mi : x),
        plan: p,
      }
    }
    case ActionTypes.ListMenuItemsUserSuccess: {
      const { plan, ingredients, recipes, shoppingLists, summary, n } = state;

      if (plan === null) {
        const plan = action.item;
        plan.setIngredients(ingredients);
        plan.setRecipes(recipes);


        let newSummary = summary;
        if (summary !== null) {
          newSummary = new Summary(plan.items);
          newSummary.setN(n);
        }

        const newShoppingLists = shoppingLists === null ? null : shoppingLists.map((x) => x.copy());
        newShoppingLists?.forEach((x) => x.setItems(plan.items));
        const shoppingListItemIds = (newShoppingLists || []).flatMap(
          (x) => x.items.map((y) => y.id)
        );
        const noShoppingListMenuItems = plan.items.filter(
          (x) => shoppingListItemIds.findIndex((y) => x.id === y) === -1
        );

        return {
          ...state,
          plan,
          summary: newSummary,
          noShoppingListMenuItems,
          shoppingLists: newShoppingLists,
        };
      }

      action.item.items.forEach((x) => plan.addItem(x));
      const shoppingListItemIds = (shoppingLists || []).flatMap(
        (x) => x.items.map((y) => y.id)
      );
      const noShoppingListMenuItems = plan.items.filter(
        (x) => shoppingListItemIds.findIndex((y) => x.id === y) === -1
      );

      return {
        ...state,
        plan,
        noShoppingListMenuItems,
      };
    }
    case ActionTypes.ListMenuItemsIdsSuccess: {
      const { plan } = state;
      if (plan === null) { return state; }

      const xs = action.items;
      xs.forEach((x) => {
        x.setIngredients(state.ingredients);
        x.setRecipes(state.recipes);
        plan.addItem(x);
        state.noShoppingListMenuItems.push(x);
      });

      return {
        ...state,
        plan: state.plan,
        noShoppingListMenuItems: state.noShoppingListMenuItems,
      };
    }
    case ActionTypes.ListCellarItemsSuccess: {
      const { ingredients, recipes, shoppingLists } = state;
      const cellar = action.item;
      if (ingredients !== undefined) {
        cellar.setIngredients(ingredients);
      }
      if (recipes !== undefined) {
        cellar.setRecipes(recipes);
      }
      if (shoppingLists !== null) {
        cellar.setShoppingLists(shoppingLists);
      }
      shoppingLists?.forEach((x) => x.setCellar(cellar));
      return {
        ...state,
        cellar,
        shoppingLists: shoppingLists === null ? null : [ ...shoppingLists ],
      };

    }
    case ActionTypes.AddCellarItemSuccess: {
      const { cellar } = state;
      if (cellar === null) { return state; }

      const c = cellar.copy();
      action.newIds.forEach((newId: string) => {
        const cellarItem = action.item.copy();
        cellarItem.id = newId;
        c.addItem(cellarItem);
      });
      return {
        ...state,
        cellar: c,
      };
    }
    case ActionTypes.DeleteCellarItemSuccess: {
      const { cellar, shoppingLists } = state;
      if (cellar === null) { return state; }

      const c = cellar.copy();
      c.removeItem(action.cellarItemId);
      if (shoppingLists !== null) {
        shoppingLists.forEach((x) => x.removeCellarItem(action.cellarItemId));
      }
      return {
        ...state,
        shoppingLists: shoppingLists === null ? null : [ ...shoppingLists ],
        cellar: c,
      }
    }
    case ActionTypes.SetCellarItemBrokenSuccess: {
      const { cellar } = state;
      if (cellar === null) { return state; }

      const c = cellar.copy();
      const item = c.items.find((x) => x.id === action.item.id);
      if (item === undefined) { return state; }
      item.setBroken();
      return {
        ...state,
        cellar: c,
      }
    }
    case ActionTypes.UnsetCellarItemBrokenSuccess: {
      const { cellar } = state;
      if (cellar === null) { return state; }

      const c = cellar.copy();
      const item = c.items.find((x) => x.id === action.item.id);
      if (item === undefined) { return state; }
      item.setUnbroken();
      return {
        ...state,
        cellar: c,
      }
    }
    case ActionTypes.SetShoppingListStatusSuccess:
      if (state.shoppingLists !== null) {
        const x = state.shoppingLists.find((x) => x.id === action.itemId);
        if (x === undefined) {
          return state;
        }
        x.status = action.status;
        const xs: ShoppingList[] = [];
        _.forEach(state.shoppingLists, (x) => xs.push(x));
        return {
          ...state,
          shoppingLists: xs,
        };
      }
      return state;
    case ActionTypes.AddMenuItemToShoppingListSuccess: {
      const { shoppingLists } = state;
      if (shoppingLists === null) { return state; }

      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) { return state; }
      sl.addItem(action.item);

      const xs: ShoppingList[] = [];
      _.forEach(shoppingLists, (x) => {
        if (x.id === sl.id) {
          xs.push(sl.copy());
        } else {
          xs.push(x);
        }
      });

      return {
        ...state,
        shoppingLists: xs,
        noShoppingListMenuItems: state.noShoppingListMenuItems.filter((x) => x.id !== action.item.id),
      };
    }
    case ActionTypes.AddMenuItemsToShoppingListSuccess: {
      const { shoppingLists } = state;
      if (shoppingLists === null) { return state; }

      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) { return state; }

      action.items.forEach((x) => sl.addItem(x));

      const ids = action.items.map((x) => x.id);
      return {
        ...state,
        shoppingLists: [ ...shoppingLists ],
        noShoppingListMenuItems: state.noShoppingListMenuItems.filter(
          (x) => ids.indexOf(x.id) === -1,
        ),
      };
    }
    case ActionTypes.DeleteMenuItemFromShoppingListSuccess: {
      const { plan, cellar, shoppingLists } = state;
      if (plan === null) { return state; }
      if (cellar === null) { return state; }
      if (shoppingLists === null) { return state; }

      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) { return state; }

      sl.removeItem(action.item.id);
      const xs: ShoppingList[] = [];
      _.forEach(shoppingLists, (x) => {
        if (x.id === sl.id) {
          xs.push(sl.copy());
        } else {
          xs.push(x);
        }
      });
      state.noShoppingListMenuItems.push(action.item);
      return {
        ...state,
        shoppingLists: xs,
        noShoppingListMenuItems: state.noShoppingListMenuItems,
      };
    }
    case ActionTypes.UpsertManualItemToShoppingListSuccess: {
      const { shoppingLists } = state;
      if (shoppingLists === null) { return state; }
      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) { return state; }
      const item = sl.manual.find((x) => x.id === action.item.id);
      if (item === undefined) {
        sl.manual.push(action.item);
      } else {
        sl.manual = sl.manual.map((x) => x.id === action.item.id ? action.item : x);
      }
      const xs: ShoppingList[] = [];
      _.forEach(shoppingLists, (x) => {
        if (x.id === sl.id) {
          xs.push(sl.copy());
        } else {
          xs.push(x);
        }
      });
      return {
        ...state,
        shoppingLists: xs,
      };
    }
    case ActionTypes.DeleteManualItemFromShoppingListSuccess: {
      const { shoppingLists } = state;
      if (shoppingLists === null) { return state; }
      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) { return state; }
      sl.manual = sl.manual.filter((x) => x.id !== action.item.id);
      const xs: ShoppingList[] = [];
      _.forEach(shoppingLists, (x) => {
        if (x.id === sl.id) {
          xs.push(sl.copy());
        } else {
          xs.push(x);
        }
      });
      return {
        ...state,
        shoppingLists: xs,
      };
    }
    case ActionTypes.AddCellarItemToShoppingListSuccess: {
      const { shoppingLists } = state;
      if (shoppingLists === null) { return state; }
      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) {
        return state;
      }
      action.item.setShoppingList(sl);
      sl.addCellarItem(action.item);

      const xs: ShoppingList[] = [];
      _.forEach(shoppingLists, (x) => {
        if (x.id === sl.id) {
          xs.push(sl.copy());
        } else {
          xs.push(x);
        }
      });
      return {
        ...state,
        shoppingLists: xs,
      };
    }
    case ActionTypes.DeleteCellarItemFromShoppingListSuccess: {
      const { cellar, shoppingLists } = state;
      if (shoppingLists === null || cellar === null) { return state; }
      action.item.setNoShoppingLists();
      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) {
        return state;
      }
      sl.removeCellarItem(action.item.id);

      const xs: ShoppingList[] = [];
      _.forEach(shoppingLists, (x) => {
        if (x.id === sl.id) {
          xs.push(sl.copy());
        } else {
          xs.push(x);
        }
      });
      return {
        ...state,
        shoppingLists: xs,
        cellar: cellar.copy(),
      };
    }
    case ActionTypes.AddPartyToShoppingListSuccess: {
      const { shoppingLists } = state;
      if (shoppingLists === null) { return state; }
      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) {
        return state;
      }
      action.item.setShoppingList(sl);
      sl.addParty(action.item);

      const xs: ShoppingList[] = [];
      _.forEach(shoppingLists, (x) => {
        if (x.id === sl.id) {
          xs.push(sl.copy());
        } else {
          xs.push(x);
        }
      });
      return {
        ...state,
        shoppingLists: xs,
      };
    }
    case ActionTypes.DeletePartyFromShoppingListSuccess: {
      const { cellar, shoppingLists } = state;
      if (shoppingLists === null || cellar === null) { return state; }
      action.item.setNoShoppingLists();
      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) {
        return state;
      }
      sl.removeParty(action.item.id);

      const xs: ShoppingList[] = [];
      _.forEach(shoppingLists, (x) => {
        if (x.id === sl.id) {
          xs.push(sl.copy());
        } else {
          xs.push(x);
        }
      });
      return {
        ...state,
        shoppingLists: xs,
        cellar: cellar.copy(),
      };
    }
    case ActionTypes.SetPurchasedStatusOnShoppingListSuccess: {
      const { shoppingLists } = state;
      if (shoppingLists === null) { return state; }
      const sl = shoppingLists.find((x) => x.id === action.shoppingListId);
      if (sl === undefined) {
        return state;
      }
      sl.setPurchasedStatus(action.recipeIngredientId, action.status);
      return {
        ...state,
        shoppingLists: [ ...shoppingLists ],
      }
    }
    case ActionTypes.CreateShoppingListSuccess:
      if (state.shoppingLists !== null && state.plan != null && state.cellar !== null) {
        const sl = new ShoppingList(new ThriftShoppingList({
          cellar: [],
          endDate: '',
          id: action.itemId,
          menu: [],
          startDate: '',
          status: ShoppingListStatus.OPEN,
          purchased: new Map<string, boolean>([]),
          manual: [],
          party: [],
          ingredients: new Map(),
          updated: '',
        }));
        sl.setItems(state.plan.items);
        sl.setCellar(state.cellar);
        return {
          ...state,
          shoppingLists: [ ...state.shoppingLists, sl ],
        };
      }
      return state;
    case ActionTypes.CreateShoppingListFromMenuItemsSuccess:
      if (state.shoppingLists !== null && state.plan != null && state.cellar !== null) {
        const sl = new ShoppingList(new ThriftShoppingList({
          cellar: [],
          endDate: '',
          id: action.itemId,
          menu: [],
          startDate: '',
          status: ShoppingListStatus.OPEN,
          purchased: new Map<string, boolean>([]),
          manual: [],
          party: [],
          ingredients: new Map(),
          updated: '',
        }));
        sl.setItems(state.plan.items);
        sl.setCellar(state.cellar);
        return {
          ...state,
          shoppingLists: [ ...state.shoppingLists, sl ],
        };
      }
      return state;
    case ActionTypes.DeleteShoppingListSuccess:
      if (state.shoppingLists !== null) {
        const xs = state.shoppingLists.filter((x) => x.id !== action.itemId);
        return {
          ...state,
          shoppingLists: xs,
        };
      }
      return state;
    case ActionTypes.GetSummary: {
      const { plan } = state;
      if (plan === null) { return state;}

      const summary = new Summary(plan.items);
      summary.setN(state.n);
      return {
        ...state,
        summary,
      };
    }
    case ActionTypes.GetAllBasicSuccess: {
      let { premenu, editedPremenu, party, cellar, plan, shoppingLists } = state;
      const parties = state.parties === null ? null : state.parties.map(
        (x) => {
          x.setRecipes(action.recipes);
          x.setIngredients(action.ingredients);
          return x.copy();
        }
      );
      if (premenu !== null) {
        premenu.menu.setIngredients(action.ingredients);
        premenu.menu.setRecipes(action.recipes);
      }
      if (editedPremenu !== null) {
        editedPremenu.menu.setIngredients(action.ingredients);
        editedPremenu.menu.setRecipes(action.recipes);
      }
      if (party !== null) {
        party.setIngredients(action.ingredients);
        party.setRecipes(action.recipes);
      }
      if (cellar !== null) {
        cellar.setIngredients(action.ingredients);
        cellar.setRecipes(action.recipes);
      }
      if (plan !== null) {
        plan.setIngredients(action.ingredients);
        plan.setRecipes(action.recipes);
      }
      if (shoppingLists !== null) {
        shoppingLists.forEach((x) => x.setIngredients(action.ingredients));
      }
      return {
        ...state,
        ingredients: action.ingredients,
        recipes: action.recipes,
        n: action.n,
        plan: plan === null ? null : plan.copy(),
        parties,
        premenu: premenu === null ? null : premenu.copy(),
        party: party === null ? null : party.copy(),
        cellar: cellar === null ? null : cellar.copy(),
        shoppingLists: shoppingLists === null ? null : [ ...shoppingLists ],
        editedPremenu: action.editedPremenu === undefined ? editedPremenu : action.editedPremenu,
      };
    }
    case ActionTypes.ListShoppingListSuccess: {
      const { plan, cellar, ingredients } = state;
      const { items } = action;
      if (plan !== null) {
        items.forEach((x) => x.setItems(plan.items));
      }
      if (ingredients !== null) {
        items.forEach((x) => x.setIngredients(ingredients));
      }
      if (cellar !== null) {
        cellar.setShoppingLists(items);
        items.forEach((x) => x.setCellar(cellar));
      }
      const shoppingListItemIds = items.flatMap(
        (x) => x.items.map((y) => y.id)
      );
      const noShoppingListMenuItems = (plan?.items || []).filter(
        (x) => shoppingListItemIds.findIndex((y) => x.id === y) === -1
      );
      return {
        ...state,
        shoppingLists: items,
        noShoppingListMenuItems,
      };
    }
    case ActionTypes.UpsertRecipeSuccess: {
      const exists = state.recipes.find((x) => x.id === action.item.id) !== undefined;
      if (!exists) {
        return {
          ...state,
          recipes: [ ...state.recipes, action.item ],
        };
      }
      return {
        ...state,
        recipes: state.recipes.map((x) => x.id === action.item.id ? action.item : x),
      }
    }
    case ActionTypes.DeleteSuccess: {
      switch (action.item.type) {
        case "recipe": {
          return {
            ...state,
            recipes: state.recipes.filter((x) => x.id !== action.item.id),
            editable: state.editable?.filter((x) => x.id !== action.item.id) || null,
          }
        }
        case "premenu": {
          return {
            ...state,
            premenus: state.premenus?.filter((x) => x.id !== action.item.id) || null,
            editable: state.editable?.filter((x) => x.id !== action.item.id) || null,
          };
        }
      }
      return state;
    }
    case ActionTypes.GetProfilePageSuccess: {
      const menu = new Menu(action.profile.menu);
      menu.setIngredients(state.ingredients);
      menu.setRecipes(state.recipes);

      return {
        ...state,
        userMenus: {
          ...state.userMenus,
          [action.profile.id]: menu,
        },
        userNames: {
          ...state.userNames,
          [action.profile.id]: action.profile.name,
        }
      }
    }
    case ActionTypes.ListPartiesSuccess: {
      const parties = Party.init(action.items);
      parties.forEach(
        (x) => {
          x.setIngredients(state.ingredients);
          x.setRecipes(state.recipes);
        }
      );
      return { ...state, parties };
    }
    case ActionTypes.DeletePartySuccess: {
      if (state.parties === null) { return state; }
      return {
        ...state,
        parties: state.parties.filter((x) => x.id !== action.item.id),
      }
    }
    case ActionTypes.UpsertPartySuccess: {
      if (state.parties === null) { return state; }
      return {
        ...state,
        parties: state.parties.map((x) => x.id !== action.item.id ? x : action.item.copy()),
      }
    }
    case ActionTypes.GetPartySuccess: {
      action.item.setIngredients(state.ingredients);
      action.item.setRecipes(state.recipes);
      return { ...state, party: action.item };
    }
    case ActionTypes.ListPremenusSuccess: {
      return { ...state, premenus: action.items };
    }
    case ActionTypes.GetPremenuSuccess: {
      action.item.menu.setIngredients(state.ingredients);
      action.item.menu.setRecipes(state.recipes);
      return { ...state, premenu: action.item };
    }
    case ActionTypes.StartPremenuMenuEditionSuccess: {
      localStorage.setItem("edited-premenu", action.item.id);
      action.item.menu.setIngredients(state.ingredients);
      action.item.menu.setRecipes(state.recipes);
      return { ...state, editedPremenu: action.item };
    }
    case ActionTypes.EndPremenuMenuEdition: {
      localStorage.setItem("edited-premenu", "");
      return { ...state, editedPremenu: null };
    }
    case ActionTypes.UpsertPremenuSuccess: {
      if (state.premenus === null) { return state; }
      if (!state.premenus.some((x) => x.id === action.item.id)) {
        return {
          ...state,
          premenus: [ ...state.premenus, action.item ],
        }
      }
      return {
        ...state,
        premenus: state.premenus.map((x) => x.id !== action.item.id ? x : action.item.copy()),
      }
    }
    case ActionTypes.ListEditable: {
      return {
        ...state,
        editable: null,
      }
    }
    case ActionTypes.ListEditableSuccess: {
      return {
        ...state,
        editable: action.items,
      }
    }
    default:
      return state;
  }
};

export const basicAllEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<ListAllAction>(ActionTypes.GetAllBasic),
  mergeMap(() => Api.listAllBasicData()),
  map(xs => ({
    type: ActionTypes.GetAllBasicSuccess,
    ingredients: xs[0],
    recipes: xs[1],
    n: xs[2],
    editedPremenu: xs[3],
  })),
);

export const addMenuItemEpic: EpicType = (action$: Observable<AllActions>, state$: StateObservable<StoreState>) => action$.pipe(
  ofType<AddMenuItemAction>(ActionTypes.AddMenuItem),
  mergeMap((action) => {
    return concat(
      of<AllActions>({
        type: CoreActionTypes.LoadingStart,
        item: action.item,
      }),
      Api.createMenuItem(
        action.item, state$.value.account.account?.currentProfile?.tagsToMeal || [],
        state$.value.basic.editedPremenu || undefined, action.date, action.q,
      ).pipe(
        mergeMap(x => of<AllActions[]>(
          {
            type: ActionTypes.AddMenuItemSuccess,
            menuItem: new MenuItem(x),
          },
          {
            type: CoreActionTypes.LoadingEnd,
            item: action.item,
          },
          {
            type: CoreActionTypes.ToastStart,
            toast: Messages.addToMenuSuccessToast(action.item),
          },
        ))
      ),
    )
  }),
);

export const deleteMenuItemEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<DeleteMenuItemAction>(ActionTypes.DeleteMenuItem),
  mergeMap((action) => {
    return concat(
      Api.deleteMenuItem(action.item.id).pipe(
        mergeMap(() => of<AllActions>(
          {
            type: ActionTypes.DeleteMenuItemSuccess,
            itemId: action.item.id,
          },
        ))
      ),
    )
  }),
);

export const updateMenuItemEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<UpdateMenuItemAction>(ActionTypes.UpdateMenuItem),
  mergeMap((action) => Api.updateMenuItem(action.menuItem)),
  map(x => {
    x.dateUpdated = Utils.nowUTC();
    return {
      type: ActionTypes.UpdateMenuItemSuccess,
      menuItem: x,
    }
  }),
);

export const listMenuItemsUserEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<ListMenuItemsUserAction>(ActionTypes.ListMenuItemsUser),
  mergeMap((action) => {
    return Api.listMenuUser(action.profile).pipe(
      map<Menu, AllActions>((item) => (
        {
          type: ActionTypes.ListMenuItemsUserSuccess,
          item,
        }
      ))
    );
  }),
);

export const listMenuItemsEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<ListMenuItemsAction>(ActionTypes.ListMenuItemsIds),
  mergeMap((action) => {
    return Api.listMenuItems(action.items).pipe(
      map<MenuItem[], AllActions>((items) => (
        {
          type: ActionTypes.ListMenuItemsIdsSuccess,
          items,
        }
      ))
    );
  }),
);

export const listCellarItemsEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<ListCellarItemsAction>(ActionTypes.ListCellarItems),
  mergeMap(() => {
    return Api.getCellar().pipe(
      map<Cellar, AllActions>((item) => (
        {
          type: ActionTypes.ListCellarItemsSuccess,
          item,
        }
      ))
    );
  }),
);

export const listShoppingListsEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<ListShoppingListAction>(ActionTypes.ListShoppingList),
  mergeMap((action) => {
    const profile = action.profile || localStorage.getItem("profile") || "";
    return Api.listShoppingLists(profile).pipe(
      map<ShoppingList[], AllActions>((items) => (
        {
          type: ActionTypes.ListShoppingListSuccess,
          items,
        }
      ))
    );
  }),
);

export const setPurchasedStatusOnShoppingListEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<SetPurchasedStatusOnShoppingListAction>(ActionTypes.SetPurchasedStatusOnShoppingList),
  mergeMap(
    (action) => Api.setPurchasedStatusOnShoppingList(action.shoppingListId, action.recipeIngredientId, action.status)
      .pipe(map<void, AllActions>(() => ({
        type: ActionTypes.SetPurchasedStatusOnShoppingListSuccess,
        shoppingListId: action.shoppingListId,
        recipeIngredientId: action.recipeIngredientId,
        status: action.status,
      })))
  ),
);

export const listProfileEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<GetProfilePageAction>(ActionTypes.GetProfilePage),
  mergeMap((action: GetProfilePageAction) => {
    return Api.getProfilePageInfo(action.userId).pipe(
      map<ProfilePageInfo, AllActions>(profile => {
          return {
            type: ActionTypes.GetProfilePageSuccess,
            profile,
          };
        }
      )
    );
  }),
);

export const listEditableEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<ListEditableAction>(ActionTypes.ListEditable),
  mergeMap((action) => Api.listEditable(action.itemType)),
  map((items) => ({ type: ActionTypes.ListEditableSuccess, items }))
);

export const listPartiesEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<ListPartiesAction>(ActionTypes.ListParties),
  mergeMap(() => Api.listParties()),
  map((items) => ({ type: ActionTypes.ListPartiesSuccess, items }))
);

export const getPartyEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<GetPartyAction>(ActionTypes.GetParty),
  mergeMap((action) => Api.getParty(action.itemId)),
  map((item) => ({ type: ActionTypes.GetPartySuccess, item }))
);

export const listPremenusEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<ListPremenusAction>(ActionTypes.ListPremenus),
  mergeMap(() => Api.listPremenus()),
  map((items) => ({ type: ActionTypes.ListPremenusSuccess, items }))
);

export const getPremenuEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<GetPremenuAction>(ActionTypes.GetPremenu),
  mergeMap((action) => Api.getPremenu(action.itemId)),
  map((item) => ({ type: ActionTypes.GetPremenuSuccess, item }))
);

export const createShoppingListEpic: EpicType = getEpic<CreateShoppingListAction, string>(
  ActionTypes.CreateShoppingList,
  () => Api.createShoppingList(),
  (action, result) => [
    {
      type: ActionTypes.CreateShoppingListSuccess,
      itemId: result,
    }
  ],
);

export const createShoppingListFromMenuItemsEpic: EpicType = getEpic<CreateShoppingListFromMenuItemsAction, string>(
  ActionTypes.CreateShoppingListFromMenuItems,
  (action) => Api.createShoppingListFromMenuItems(action.daysBack),
  (action, result) => [
    {
      type: ActionTypes.CreateShoppingListFromMenuItemsSuccess,
      itemId: result,
      daysBack: action.daysBack,
    }
  ],
);

export const deleteShoppingListEpic: EpicType = getEpic<DeleteShoppingListAction, string>(
  ActionTypes.DeleteShoppingList,
  (action) => Api.deleteShoppingList(action.item.id),
  (action) => [
    {
      type: ActionTypes.DeleteShoppingListSuccess,
      itemId: action.item.id,
    }
  ],
);

export const setShoppingListStatusEpic: EpicType = getEpic<SetShoppingListStatusAction, void>(
  ActionTypes.SetShoppingListStatus,
  (action) => Api.setShoppingListStatus(action.item.id, action.status),
  (action) => [
    {
      type: ActionTypes.SetShoppingListStatusSuccess,
      itemId: action.item.id,
      status: action.status,
    },
  ],
);

export const usePremenuEpic: EpicType = getEpic<UsePremenuAction, string[]>(
  ActionTypes.UsePremenu,
  (action) => Api.addToPremenu(action.item.id, action.startDate, action.item.minDate, action.daysNum, action.factor),
  (action, items) => [
    {
      type: ActionTypes.ListMenuItemsIds,
      items,
    },
  ],
);

export const addMenuItemToShoppingListEpic: EpicType = getEpic<AddMenuItemToShoppingListAction, void>(
  ActionTypes.AddMenuItemToShoppingList,
  (action) => Api.addMenuItemToShoppingList(action.shoppingListId, action.item.id),
  (action) => [
    {
      type: ActionTypes.AddMenuItemToShoppingListSuccess,
      item: action.item,
      shoppingListId: action.shoppingListId,
    },
  ],
);

export const addMenuItemsToShoppingListEpic: EpicType = getEpic<AddMenuItemsToShoppingListAction, void>(
  ActionTypes.AddMenuItemsToShoppingList,
  (action) => Api.addMenuItemsToShoppingList(action.shoppingListId, action.items.map((x) => x.id)),
  (action) => [
    {
      type: ActionTypes.AddMenuItemsToShoppingListSuccess,
      items: action.items,
      shoppingListId: action.shoppingListId,
    },
  ],
);

export const deleteMenuItemFromShoppingListEpic: EpicType = getEpic<DeleteMenuItemFromShoppingListAction, void>(
  ActionTypes.DeleteMenuItemFromShoppingList,
  (action) => Api.deleteMenuItemFromShoppingList(action.shoppingListId, action.item.id),
  (action) => [
    {
      type: ActionTypes.DeleteMenuItemFromShoppingListSuccess,
      item: action.item,
      shoppingListId: action.shoppingListId,
    },
  ],
);

export const addCellarItemToShoppingListEpic: EpicType = getEpic<AddCellarItemToShoppingListAction, void>(
  ActionTypes.AddCellarItemToShoppingList,
  (action) => Api.addCellarItemToShoppingList(action.shoppingListId, action.item.id),
  (action) => [
    {
      type: ActionTypes.AddCellarItemToShoppingListSuccess,
      shoppingListId: action.shoppingListId,
      item: action.item,
    },
  ],
);

export const deleteCellarItemFromShoppingListEpic: EpicType = getEpic<DeleteCellarItemFromShoppingListAction, void>(
  ActionTypes.DeleteCellarItemFromShoppingList,
  (action) => Api.deleteCellarItemFromShoppingList(action.shoppingListId, action.item.id),
  (action) => [
    {
      type: ActionTypes.DeleteCellarItemFromShoppingListSuccess,
      shoppingListId: action.shoppingListId,
      item: action.item,
    },
  ],
);

export const addPartyToShoppingListEpic: EpicType = getEpic<AddPartyToShoppingListAction, void>(
  ActionTypes.AddPartyToShoppingList,
  (action) => Api.addPartyToShoppingList(action.shoppingListId, action.item.id),
  (action) => [
    {
      type: ActionTypes.AddPartyToShoppingListSuccess,
      shoppingListId: action.shoppingListId,
      item: action.item,
    },
  ],
);

export const deletePartyFromShoppingListEpic: EpicType = getEpic<DeletePartyFromShoppingListAction, void>(
  ActionTypes.DeletePartyFromShoppingList,
  (action) => Api.deletePartyFromShoppingList(action.shoppingListId, action.item.id),
  (action) => [
    {
      type: ActionTypes.DeletePartyFromShoppingListSuccess,
      shoppingListId: action.shoppingListId,
      item: action.item,
    },
  ],
);

export const upsertManualItemToShoppingListEpic: EpicType = getEpic<UpsertManualItemToShoppingListAction, string>(
  ActionTypes.UpsertManualItemToShoppingList,
  (action) => Api.upsertManualItemToShoppingList(action.shoppingListId, action.item),
  (action, newId) => {
    action.item.id = newId;
    return [
      {
        type: ActionTypes.UpsertManualItemToShoppingListSuccess,
        shoppingListId: action.shoppingListId,
        item: action.item,
      },
    ];
  },
);

export const deleteManualItemFromShoppingListEpic: EpicType = getEpic<DeleteManualItemFromShoppingListAction, void>(
  ActionTypes.DeleteManualItemFromShoppingList,
  (action) => Api.deleteManualItemFromShoppingList(action.shoppingListId, action.item.id),
  (action) => [
    {
      type: ActionTypes.DeleteManualItemFromShoppingListSuccess,
      shoppingListId: action.shoppingListId,
      item: action.item,
    },
  ],
);

export const addCellarItemEpic: EpicType = getEpic<AddCellarItemAction, string[]>(
  ActionTypes.AddCellarItem,
  (action) => Api.addCellarItems(action.item, action.howMany),
  (action, newIds) => [
    {
      type: ActionTypes.AddCellarItemSuccess,
      item: action.item,
      newIds,
    },
  ],
);

export const setCellarItemBrokenEpic: EpicType = getEpic<SetCellarItemBrokenAction, void>(
  ActionTypes.SetCellarItemBroken,
  (action) => Api.setCellarItemBroken(action.item.id),
  (action) => [
    {
      type: ActionTypes.SetCellarItemBrokenSuccess,
      item: action.item,
    },
  ],
);

export const unsetCellarItemBrokenEpic: EpicType = getEpic<UnsetCellarItemBrokenAction, void>(
  ActionTypes.UnsetCellarItemBroken,
  (action) => Api.unsetCellarItemBroken(action.item.id),
  (action) => [
    {
      type: ActionTypes.UnsetCellarItemBrokenSuccess,
      item: action.item,
    },
  ],
);

export const deleteCellarItemEpic: EpicType = getEpic<DeleteCellarItemAction, void>(
  ActionTypes.DeleteCellarItem,
  (action) => Api.deleteCellarItem(action.item.id),
  (action) => [
    {
      type: ActionTypes.DeleteCellarItemSuccess,
      cellarItemId: action.item.id,
    },
  ],
);

export const upsertPartyEpic: EpicType = getEpic<UpsertPartyAction, string>(
  ActionTypes.UpsertParty,
  (action) => Api.upsertParty(action.item),
  (action, newId) => {
    action.item.id = newId;
    return [
      {
        type: ActionTypes.UpsertPartySuccess,
        item: action.item,
      },
      {
        type: CoreActionTypes.LoadingEnd,
        item: Party.empty(),
      },
    ];
  },
);

export const deletePartyEpic: EpicType = getEpic<DeletePartyAction, void>(
  ActionTypes.DeleteParty,
  (action) => Api.deleteParty(action.item.id),
  (action) => [
    {
      type: ActionTypes.DeletePartySuccess,
      item: action.item,
    },
  ],
);

export const upsertPremenuEpic: EpicType = getEpic<UpsertPremenuAction, string>(
  ActionTypes.UpsertPremenu,
  (action) => Api.upsertPremenu(action.item),
  (action, newId) => {
    action.item.id = newId;
    return [
      {
        type: ActionTypes.UpsertPremenuSuccess,
        item: action.item,
      },
      {
        type: CoreActionTypes.LoadingEnd,
        item: Premenu.empty(),
      },
    ];
  },
);

export const startPremenuEditionEpic: EpicType = getEpic<StartPremenuMenuEditionAction, [ Menu, Premenu ]>(
  ActionTypes.StartPremenuMenuEdition,
  (action) => {
    const r1: Observable<Menu> = Api.listMenuPremenu(action.item.id);
    const r2: Observable<Premenu> = Api.getPremenu(action.item.id);
    return zip(r1, r2);
  },
  (action, apiResult) => {
    const result = apiResult[1];
    result.menu = apiResult[0];
    return [
      { type: ActionTypes.StartPremenuMenuEditionSuccess, item: result },
    ];
  }
);

export const upsertRecipeEpic: EpicType = getEpic<UpsertRecipeAction, string>(
  ActionTypes.UpsertRecipe,
  (action) => Api.upsertRecipe(action.item),
  (action, newId) => {
    const extra: AllActions[] = [];
    if (action.item.id === "new" || action.item.id === "") {
      extra.push(
        {
          type: CoreActionTypes.LoadingEnd,
          item: Recipe.empty(),
        },
        {
          type: CoreActionTypes.ToastStart,
          toast: Messages.createdRecipeSuccessToast(action.item),
        },
      );
    } else {
      extra.push(
        {
          type: CoreActionTypes.ToastStart,
          toast: Messages.updatedRecipeSuccessToast(action.item),
        }
      );
    }
    action.item.id = newId;
    return [
      {
        type: ActionTypes.UpsertRecipeSuccess,
        item: action.item,
      },
      ...extra,
    ];
  },
);

export const deleteEpic: EpicType = getEpic<DeleteAction, void>(
  ActionTypes.Delete,
  (action) => {
    switch (action.item.type) {
      case "recipe": {
        return Api.deleteRecipe(action.item.id)
      }
      case "premenu": {
        return Api.deletePremenu(action.item.id)
      }
      default: {
        return of();
      }
    }
  },
  (action) => [
    {
      type: ActionTypes.DeleteSuccess,
      item: action.item,
    },
    {
      type: CoreActionTypes.ToastStart,
      toast: Messages.deleteSuccessToast(action.item),
    },
  ],
);

export const revalidateCacheEpic: EpicType = (action$: Observable<AllActions>) => action$.pipe(
  ofType<RevalidateCacheAction>(CoreActionTypes.RevalidateCache),
  mergeMap((inf) => Api.getCacheRevalidationInfo()),
  mergeMap(recache => {

    const actions: AllActions[] = [];
    if (recache.indexOf("shoppinglists") !== -1) {
      actions.push({
        type: ActionTypes.ListShoppingList,
      });
    }
    if (recache.indexOf("premenus") !== -1) {
      actions.push({
        type: ActionTypes.ListPremenus,
      });
    }
    if (recache.indexOf("ingredients") !== -1) {
      actions.push({
        type: ActionTypes.GetAllBasic,
      });
    }
    if (recache.indexOf("recipes") !== -1) {
      actions.push({
        type: ActionTypes.GetAllBasic,
      });
    }

    return concat<AllActions[]>(actions);
  }),
);