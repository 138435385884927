import { combineReducers, configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { combineEpics, createEpicMiddleware, Epic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Epics as CoreEpics, Reducers as CoreReducers, StoreState as CoreStoreState } from '@stack/frontend-core/cls';

import { accountReducer, AccountState, deleteProfileEpic, getAccountEpic, saveProfileEpic, } from 'cls/redux/account';
import {
  addCellarItemEpic,
  addCellarItemToShoppingListEpic,
  addMenuItemEpic,
  addMenuItemsToShoppingListEpic,
  addMenuItemToShoppingListEpic,
  addPartyToShoppingListEpic,
  basicAllEpic,
  basicReducer,
  BasicState,
  createShoppingListEpic, createShoppingListFromMenuItemsEpic,
  deleteCellarItemEpic,
  deleteCellarItemFromShoppingListEpic,
  deleteEpic,
  deleteManualItemFromShoppingListEpic,
  deleteMenuItemEpic,
  deleteMenuItemFromShoppingListEpic,
  deletePartyEpic,
  deletePartyFromShoppingListEpic,
  deleteShoppingListEpic,
  getPartyEpic,
  getPremenuEpic,
  listCellarItemsEpic,
  listEditableEpic,
  listMenuItemsEpic,
  listMenuItemsUserEpic,
  listPartiesEpic,
  listPremenusEpic,
  listProfileEpic,
  listShoppingListsEpic,
  revalidateCacheEpic,
  setCellarItemBrokenEpic,
  setPurchasedStatusOnShoppingListEpic,
  setShoppingListStatusEpic,
  startPremenuEditionEpic,
  unsetCellarItemBrokenEpic,
  updateMenuItemEpic,
  upsertManualItemToShoppingListEpic,
  upsertPartyEpic,
  upsertPremenuEpic,
  upsertRecipeEpic,
  usePremenuEpic,
} from 'cls/redux/basic';
import EpicType from 'cls/redux/epictype';
import { getRecommendationsEpic, recommendationsReducer, RecommendationsState } from 'cls/redux/recommendations';
import { sidebarReducer, SidebarState } from 'cls/redux/sidebar';


export interface StoreState extends CoreStoreState {
  account: AccountState;
  basic: BasicState;
  recommendations: RecommendationsState,
  sidebar: SidebarState;
}

const rootReducer = combineReducers({
  ...CoreReducers,

  account: accountReducer,
  basic: basicReducer,
  recommendations: recommendationsReducer,
  sidebar: sidebarReducer,
});

const epicMiddleware = createEpicMiddleware();

function protect(epic: EpicType): EpicType {
  return (action$: Observable<AnyAction>, state$: any, deps: any) =>
    epic(action$, state$, deps).pipe(
      catchError(error => of<AnyAction>({ type: 'EPIC_FAILURE', error })),
    );
}

const epics: EpicType[] = [
  ...CoreEpics,

  getAccountEpic, saveProfileEpic, deleteProfileEpic,
  basicAllEpic, revalidateCacheEpic,
  addMenuItemEpic,
  deleteMenuItemEpic, updateMenuItemEpic, listMenuItemsEpic, listMenuItemsUserEpic,
  setShoppingListStatusEpic, deleteShoppingListEpic, listShoppingListsEpic,
  createShoppingListEpic, createShoppingListFromMenuItemsEpic,
  addMenuItemToShoppingListEpic, addMenuItemsToShoppingListEpic, deleteMenuItemFromShoppingListEpic,
  upsertManualItemToShoppingListEpic, deleteManualItemFromShoppingListEpic,
  addCellarItemToShoppingListEpic, deleteCellarItemFromShoppingListEpic,
  addPartyToShoppingListEpic, deletePartyFromShoppingListEpic,
  setPurchasedStatusOnShoppingListEpic,
  addCellarItemEpic, deleteCellarItemEpic, listCellarItemsEpic, setCellarItemBrokenEpic, unsetCellarItemBrokenEpic,
  upsertRecipeEpic,
  getRecommendationsEpic,
  listProfileEpic,
  upsertPartyEpic, deletePartyEpic, listPartiesEpic, getPartyEpic,
  upsertPremenuEpic, listPremenusEpic, getPremenuEpic, startPremenuEditionEpic, usePremenuEpic,
  listEditableEpic,
  deleteEpic,
];

const protectedEpics = Array.from(epics).map(protect);

const rootEpic: Epic<any, any, any, any> = combineEpics(...protectedEpics);

const store = configureStore({
  reducer: rootReducer,
  middleware: new MiddlewareArray().concat(epicMiddleware),
});
epicMiddleware.run(rootEpic);

export default store;

export type StoreDispatch = typeof store.dispatch;