import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';

import { AllActions as CoreAllActions } from '@stack/frontend-core/cls/redux/actions';

import Account, { Profile } from 'cls/Account';
import Cellar from 'cls/Cellar';
import CellarItem from 'cls/CellarItem';
import Ingredient from 'cls/Ingredient';
import { ToMenuItem } from 'cls/Interfaces';
import { Item } from 'cls/Item';
import ManualItem from 'cls/ManualItem';
import Menu from 'cls/Menu';
import MenuItem from 'cls/MenuItem';
import { N } from 'cls/N';
import Party from 'cls/Party';
import Premenu from 'cls/Premenu';
import Quantity from 'cls/Quantity';
import Recipe from 'cls/Recipe';
import Recommendations from 'cls/Recommandation';
import ShoppingList from 'cls/ShoppingList';
import { Party as ThriftParty, ProfilePageInfo, ShoppingListStatus, SR } from 'thriftgen';


export enum ActionTypes {
  GetAccount = 'GET_ACCOUNT',
  GetAccountSuccess = 'GET_ACCOUNT_SUCCESS',
  SetProfile = 'SET_PROFILE',
  SaveProfile = 'SAVE_PROFILE',
  SaveProfileSuccess = 'SAVE_PROFILE_SUCCESS',
  DeleteProfile = 'DELETE_PROFILE',

  GetSummary = 'GET_SUMMARY',

  GetAllBasic = 'GET_ALL_BASIC',
  GetAllBasicSuccess = 'GET_ALL_BASIC_SUCCESS',

  ToggleSidebar = 'TOGGLE_SIDEBAR',
  CloseSidebar = 'CLOSE_SIDEBAR',

  UpsertRecipe = 'UPSERT_RECIPE_ITEM',
  UpsertRecipeSuccess = 'UPSERT_RECIPE_ITEM_SUCCESS',

  Delete = 'DELETE_ITEM',
  DeleteSuccess = 'DELETE_ITEM_SUCCESS',

  AddMenuItem = 'ADD_MENU_ITEM',
  AddMenuItemSuccess = 'ADD_MENU_ITEM_SUCCESS',
  DeleteMenuItem = 'DELETE_MENU_ITEM',
  DeleteMenuItemSuccess = 'DELETE_MENU_ITEM_SUCCESS',
  UpdateMenuItem = 'UPDATE_MENU_ITEM',
  UpdateMenuItemSuccess = 'UPDATE_MENU_ITEM_SUCCESS',
  ListMenuItemsUser = 'LIST_MENU_ITEMS_USER',
  ListMenuItemsUserSuccess = 'LIST_MENU_ITEMS_USER_SUCCESS',
  ListMenuItemsIds = 'LIST_MENU_ITEMS',
  ListMenuItemsIdsSuccess = 'LIST_MENU_ITEMS_SUCCESS',

  CreateShoppingList = 'CREATE_SHOPPING_LIST',
  CreateShoppingListSuccess = 'CREATE_SHOPPING_LIST_SUCCESS',
  CreateShoppingListFromMenuItems = 'CREATE_SHOPPING_LIST_FROM_MENU_ITEMS',
  CreateShoppingListFromMenuItemsSuccess = 'CREATE_SHOPPING_LIST_FROM_MENU_ITEMS_SUCCESS',
  ListShoppingList = 'LIST_SHOPPING_LIST',
  ListShoppingListSuccess = 'LIST_SHOPPING_LIST_SUCCESS',
  DeleteShoppingList = 'DELETE_SHOPPING_LIST',
  DeleteShoppingListSuccess = 'DELETE_SHOPPING_LIST_SUCCESS',
  SetShoppingListStatus = 'SET_SHOPPING_LIST_STATUS',
  SetShoppingListStatusSuccess = 'SET_SHOPPING_LIST_STATUS_SUCCESS',
  SetPurchasedStatusOnShoppingList = 'SET_PUrCHASED_STATUS_ON_SHOPPING_LIST',
  SetPurchasedStatusOnShoppingListSuccess = 'SET_PUrCHASED_STATUS_ON_SHOPPING_LIST_SUCCESS',

  AddMenuItemToShoppingList = 'ADD_MENU_ITEM_SHOPPING_LIST',
  AddMenuItemToShoppingListSuccess = 'ADD_MENU_ITEM_SHOPPING_LIST_SUCCESS',
  AddMenuItemsToShoppingList = 'ADD_MENU_ITEMS_SHOPPING_LIST',
  AddMenuItemsToShoppingListSuccess = 'ADD_MENU_ITEMS_SHOPPING_LIST_SUCCESS',
  DeleteMenuItemFromShoppingList = 'DELETE_MENU_ITEM_SHOPPING_LIST',
  DeleteMenuItemFromShoppingListSuccess = 'DELETE_MENU_ITEM_SHOPPING_LIST_SUCCESS',
  AddCellarItemToShoppingList = 'ADD_CELLAR_ITEM_SHOPPING_LIST',
  AddCellarItemToShoppingListSuccess = 'ADD_CELLAR_ITEM_SHOPPING_LIST_SUCCESS',
  DeleteCellarItemFromShoppingList = 'DELETE_CELLAR_ITEM_SHOPPING_LIST',
  DeleteCellarItemFromShoppingListSuccess = 'DELETE_CELLAR_ITEM_SHOPPING_LIST_SUCCESS',
  UpsertManualItemToShoppingList = 'UPSERT_MANUAL_ITEM_SHOPPING_LIST',
  UpsertManualItemToShoppingListSuccess = 'UPSERT_MANUAL_ITEM_SHOPPING_LIST_SUCCESS',
  DeleteManualItemFromShoppingList = 'DELETE_MANUAL_ITEM_SHOPPING_LIST',
  DeleteManualItemFromShoppingListSuccess = 'DELETE_MANUAL_ITEM_SHOPPING_LIST_SUCCESS',
  AddPartyToShoppingList = 'ADD_PARTY_SHOPPING_LIST',
  AddPartyToShoppingListSuccess = 'ADD_PARTY_SHOPPING_LIST_SUCCESS',
  DeletePartyFromShoppingList = 'DELETE_PARTY_SHOPPING_LIST',
  DeletePartyFromShoppingListSuccess = 'DELETE_PARTY_SHOPPING_LIST_SUCCESS',

  AddCellarItem = 'ADD_CELLAR_ITEM',
  AddCellarItemSuccess = 'ADD_CELLAR_ITEM_SUCCESS',
  SetCellarItemBroken = 'SET_CELLAR_ITEM_BROKEN',
  UnsetCellarItemBroken = 'UNSET_CELLAR_ITEM_BROKEN',
  SetCellarItemBrokenSuccess = 'SET_CELLAR_ITEM_BROKEN_SUCCESS',
  UnsetCellarItemBrokenSuccess = 'UNSET_CELLAR_ITEM_BROKEN_SUCCESS',
  DeleteCellarItem = 'DELETE_CELLAR_ITEM',
  DeleteCellarItemSuccess = 'DELETE_CELLAR_ITEM_SUCCESS',
  ListCellarItems = 'CELLAR_LIST',
  ListCellarItemsSuccess = 'CELLAR_LIST_SUCCESS',

  GetRecommendations = 'GET_RECOMMENDATIONS',
  GetRecommendationsSuccess = 'GET_RECOMMENDATIONS_SUCCESS',

  GetProfilePage = 'GET_PROFILE_PAGE',
  GetProfilePageSuccess = 'GET_PROFILE_PAGE_SUCCESS',

  UpsertParty = 'UPSERT_PARTY',
  UpsertPartySuccess = 'UPSERT_PARTY_SUCCESS',
  DeleteParty = 'DELETE_PARTY',
  DeletePartySuccess = 'DELETE_PARTY_SUCCESS',
  ListParties = 'PARTY_LIST',
  ListPartiesSuccess = 'PARTY_LIST_SUCCESS',
  GetParty = 'PARTY_GET',
  GetPartySuccess = 'PARTY_GET_SUCCESS',
  UseParty = 'PARTY_USE',
  UsePartySuccess = 'PARTY_USE_SUCCESS',

  ListPremenus = 'PREMENU_LIST',
  ListPremenusSuccess = 'PREMENU_LIST_SUCCESS',
  UpsertPremenu = 'UPSERT_PREMENU',
  UpsertPremenuSuccess = 'UPSERT_PREMENU_SUCCESS',
  GetPremenu = 'PREMENU_GET',
  GetPremenuSuccess = 'PREMENU_GET_SUCCESS',
  StartPremenuMenuEdition = 'PREMENU_EDITION_START',
  StartPremenuMenuEditionSuccess = 'PREMENU_EDITION_START_SUCCESS',
  EndPremenuMenuEdition = 'PREMENU_EDITION_END',
  UsePremenu = 'PREMENU_USE',
  UsePremenuSuccess = 'PREMENU_USE_SUCCESS',

  ListEditable = 'LIST_EDITABLE',
  ListEditableSuccess = 'LIST_EDITABLE_SUCCESS',
}


export interface GetSummaryAction extends Action {
  type: ActionTypes.GetSummary,
}

export interface ListAllAction extends Action {
  type: ActionTypes.GetAllBasic,
}

export interface ListAllSuccessAction extends Action {
  type: ActionTypes.GetAllBasicSuccess,
  ingredients: Ingredient[],
  recipes: Recipe[],
  n: N,
  editedPremenu: Premenu | undefined,
}

export interface AddMenuItemAction extends Action {
  type: ActionTypes.AddMenuItem,
  item: ToMenuItem,
  date?: string,
  q?: Quantity,
}

export interface AddMenuItemSuccessAction extends Action {
  type: ActionTypes.AddMenuItemSuccess,
  menuItem: MenuItem,
}

export interface DeleteMenuItemAction extends Action {
  type: ActionTypes.DeleteMenuItem,
  item: MenuItem,
}

export interface DeleteMenuItemSuccessAction extends Action {
  type: ActionTypes.DeleteMenuItemSuccess,
  itemId: string,
}

export interface UpdateMenuItemAction extends Action {
  type: ActionTypes.UpdateMenuItem,
  menuItem: MenuItem,
}

export interface UpdateMenuItemSuccessAction extends Action {
  type: ActionTypes.UpdateMenuItemSuccess,
  menuItem: MenuItem,
}

export interface ListMenuItemsUserAction extends Action {
  type: ActionTypes.ListMenuItemsUser,
  profile: string,
}

export interface ListMenuItemsUserSuccessAction extends Action {
  type: ActionTypes.ListMenuItemsUserSuccess,
  item: Menu,
}

export interface ListMenuItemsAction extends Action {
  type: ActionTypes.ListMenuItemsIds,
  items: string[],
}

export interface ListMenuItemsSuccessAction extends Action {
  type: ActionTypes.ListMenuItemsIdsSuccess,
  items: MenuItem[],
}

export interface ListShoppingListAction extends Action {
  type: ActionTypes.ListShoppingList,
  profile?: string;
}

export interface ListShoppingListSuccessAction extends Action {
  type: ActionTypes.ListShoppingListSuccess,
  items: ShoppingList[],
}

export interface SetShoppingListStatusAction extends Action {
  type: ActionTypes.SetShoppingListStatus,
  item: ShoppingList,
  status: ShoppingListStatus,
}

export interface SetShoppingListStatusSuccessAction extends Action {
  type: ActionTypes.SetShoppingListStatusSuccess,
  itemId: string,
  status: ShoppingListStatus,
}

export interface CreateShoppingListAction extends Action {
  type: ActionTypes.CreateShoppingList,
  redirect?: (action: CreateShoppingListAction, result: string) => string,
  navigate?: NavigateFunction,
}

export interface CreateShoppingListSuccessAction extends Action {
  type: ActionTypes.CreateShoppingListSuccess,
  itemId: string,
}

export interface CreateShoppingListFromMenuItemsAction extends Action {
  type: ActionTypes.CreateShoppingListFromMenuItems,
  daysBack: number,
  redirect?: (action: CreateShoppingListFromMenuItemsAction, result: string) => string,
  navigate?: NavigateFunction,
}

export interface CreateShoppingListFromMenuItemsSuccessAction extends Action {
  type: ActionTypes.CreateShoppingListFromMenuItemsSuccess,
  itemId: string,
  daysBack: number,
}

export interface DeleteShoppingListAction extends Action {
  type: ActionTypes.DeleteShoppingList,
  item: ShoppingList,
}

export interface DeleteShoppingListSuccessAction extends Action {
  type: ActionTypes.DeleteShoppingListSuccess,
  itemId: string,
}

export interface AddMenuItemToShoppingListAction extends Action {
  type: ActionTypes.AddMenuItemToShoppingList,
  item: MenuItem,
  shoppingListId: string,
}

export interface AddMenuItemToShoppingListSuccessAction extends Action {
  type: ActionTypes.AddMenuItemToShoppingListSuccess,
  item: MenuItem,
  shoppingListId: string,
}

export interface AddMenuItemsToShoppingListAction extends Action {
  type: ActionTypes.AddMenuItemsToShoppingList,
  items: MenuItem[],
  shoppingListId: string,
}

export interface AddMenuItemsToShoppingListSuccessAction extends Action {
  type: ActionTypes.AddMenuItemsToShoppingListSuccess,
  items: MenuItem[],
  shoppingListId: string,
}

export interface DeleteMenuItemFromShoppingListAction extends Action {
  type: ActionTypes.DeleteMenuItemFromShoppingList,
  item: MenuItem,
  shoppingListId: string,
}

export interface DeleteMenuItemFromShoppingListSuccessAction extends Action {
  type: ActionTypes.DeleteMenuItemFromShoppingListSuccess,
  item: MenuItem,
  shoppingListId: string,
}

export interface AddCellarItemToShoppingListAction extends Action {
  type: ActionTypes.AddCellarItemToShoppingList,
  item: CellarItem,
  shoppingListId: string,
}

export interface AddCellarItemToShoppingListSuccessAction extends Action {
  type: ActionTypes.AddCellarItemToShoppingListSuccess,
  item: CellarItem,
  shoppingListId: string,
}

export interface DeleteCellarItemFromShoppingListAction extends Action {
  type: ActionTypes.DeleteCellarItemFromShoppingList,
  item: CellarItem,
  shoppingListId: string,
}

export interface DeleteCellarItemFromShoppingListSuccessAction extends Action {
  type: ActionTypes.DeleteCellarItemFromShoppingListSuccess,
  item: CellarItem,
  shoppingListId: string,
}

export interface AddPartyToShoppingListAction extends Action {
  type: ActionTypes.AddPartyToShoppingList,
  item: Party,
  shoppingListId: string,
}

export interface AddPartyToShoppingListSuccessAction extends Action {
  type: ActionTypes.AddPartyToShoppingListSuccess,
  item: Party,
  shoppingListId: string,
}

export interface DeletePartyFromShoppingListAction extends Action {
  type: ActionTypes.DeletePartyFromShoppingList,
  item: Party,
  shoppingListId: string,
}

export interface DeletePartyFromShoppingListSuccessAction extends Action {
  type: ActionTypes.DeletePartyFromShoppingListSuccess,
  item: Party,
  shoppingListId: string,
}


export interface UpsertManualItemToShoppingListAction extends Action {
  type: ActionTypes.UpsertManualItemToShoppingList,
  shoppingListId: string,
  item: ManualItem,
}

export interface UpsertManualItemToShoppingListSuccessAction extends Action {
  type: ActionTypes.UpsertManualItemToShoppingListSuccess,
  shoppingListId: string,
  item: ManualItem,
}

export interface DeleteManualItemFromShoppingListAction extends Action {
  type: ActionTypes.DeleteManualItemFromShoppingList,
  shoppingListId: string,
  item: ManualItem,
}

export interface DeleteManualItemFromShoppingListSuccessAction extends Action {
  type: ActionTypes.DeleteManualItemFromShoppingListSuccess,
  shoppingListId: string,
  item: ManualItem,
}

export interface SetPurchasedStatusOnShoppingListAction extends Action {
  type: ActionTypes.SetPurchasedStatusOnShoppingList,
  shoppingListId: string,
  recipeIngredientId: string,
  status: boolean,
}

export interface SetPurchasedStatusOnShoppingListSuccessAction extends Action {
  type: ActionTypes.SetPurchasedStatusOnShoppingListSuccess,
  shoppingListId: string,
  recipeIngredientId: string,
  status: boolean,
}

export interface ListCellarItemsAction extends Action {
  type: ActionTypes.ListCellarItems,
}

export interface ListCellarItemsSuccessAction extends Action {
  type: ActionTypes.ListCellarItemsSuccess,
  item: Cellar,
}

export interface AddCellarItemAction extends Action {
  type: ActionTypes.AddCellarItem,
  item: CellarItem,
  howMany: number,
}

export interface AddCellarItemSuccessAction extends Action {
  type: ActionTypes.AddCellarItemSuccess,
  item: CellarItem,
  newIds: string[],
}

export interface SetCellarItemBrokenAction extends Action {
  type: ActionTypes.SetCellarItemBroken,
  item: Item,
}

export interface SetCellarItemBrokenSuccessAction extends Action {
  type: ActionTypes.SetCellarItemBrokenSuccess,
  item: Item,
}

export interface UnsetCellarItemBrokenAction extends Action {
  type: ActionTypes.UnsetCellarItemBroken
  item: Item,
}

export interface UnsetCellarItemBrokenSuccessAction extends Action {
  type: ActionTypes.UnsetCellarItemBrokenSuccess,
  item: Item,
}

export interface DeleteCellarItemAction extends Action {
  type: ActionTypes.DeleteCellarItem,
  item: CellarItem,
}

export interface DeleteCellarItemSuccessAction extends Action {
  type: ActionTypes.DeleteCellarItemSuccess,
  cellarItemId: string,
}

export interface UpsertRecipeAction extends Action {
  type: ActionTypes.UpsertRecipe,
  item: Recipe,
}

export interface UpsertRecipeSuccessAction extends Action {
  type: ActionTypes.UpsertRecipeSuccess,
  item: Recipe,
}

export interface DeleteAction extends Action {
  type: ActionTypes.Delete,
  item: Item,
}

export interface DeleteSuccessAction extends Action {
  type: ActionTypes.DeleteSuccess,
  item: Item,
}

export interface GetProfilePageAction extends Action {
  type: ActionTypes.GetProfilePage,
  userId: string,
}

export interface GetProfilePageSuccessAction extends Action {
  type: ActionTypes.GetProfilePageSuccess,
  profile: ProfilePageInfo,
}

export interface UpsertPartyAction extends Action {
  type: ActionTypes.UpsertParty,
  item: Party,
}

export interface UpsertPartySuccessAction extends Action {
  type: ActionTypes.UpsertPartySuccess,
  item: Party,
}

export interface DeletePartyAction extends Action {
  type: ActionTypes.DeleteParty,
  item: Party,
}

export interface DeletePartySuccessAction extends Action {
  type: ActionTypes.DeletePartySuccess,
  item: Party,
}

export interface ListPartiesAction extends Action {
  type: ActionTypes.ListParties,
}

export interface ListPartiesSuccessAction extends Action {
  type: ActionTypes.ListPartiesSuccess,
  items: ThriftParty[],
}

export interface GetPartyAction extends Action {
  type: ActionTypes.GetParty,
  itemId: string,
}

export interface GetPartySuccessAction extends Action {
  type: ActionTypes.GetPartySuccess,
  item: Party,
}

export interface ListPremenusAction extends Action {
  type: ActionTypes.ListPremenus,
}

export interface ListPremenusSuccessAction extends Action {
  type: ActionTypes.ListPremenusSuccess,
  items: Premenu[],
}

export interface GetPremenuAction extends Action {
  type: ActionTypes.GetPremenu,
  itemId: string,
}

export interface GetPremenuSuccessAction extends Action {
  type: ActionTypes.GetPremenuSuccess,
  item: Premenu,
}

export interface UpsertPremenuAction extends Action {
  type: ActionTypes.UpsertPremenu
  item: Premenu,
}

export interface UpsertPremenuSuccessAction extends Action {
  type: ActionTypes.UpsertPremenuSuccess,
  item: Premenu,
}

export interface StartPremenuMenuEditionAction extends Action {
  type: ActionTypes.StartPremenuMenuEdition
  item: SR,
}

export interface StartPremenuMenuEditionSuccessAction extends Action {
  type: ActionTypes.StartPremenuMenuEditionSuccess
  item: Premenu,
}

export interface EndPremenuMenuEditionAction extends Action {
  type: ActionTypes.EndPremenuMenuEdition,
}

export interface UsePremenuAction extends Action {
  type: ActionTypes.UsePremenu
  item: Premenu,
  startDate: string,
  daysNum: number,
  factor: number,
}

export interface ListEditableAction extends Action {
  type: ActionTypes.ListEditable,
  itemType: string,
}

export interface ListEditableSuccessAction extends Action {
  type: ActionTypes.ListEditableSuccess,
  items: SR[],
}

export type BasicActions =
  GetSummaryAction |
  ListAllAction |
  ListAllSuccessAction |
  AddMenuItemAction |
  AddMenuItemSuccessAction |
  DeleteMenuItemAction |
  DeleteMenuItemSuccessAction |
  UpdateMenuItemAction |
  UpdateMenuItemSuccessAction |
  ListShoppingListAction |
  ListShoppingListSuccessAction |
  SetShoppingListStatusAction |
  SetShoppingListStatusSuccessAction |
  CreateShoppingListAction |
  CreateShoppingListSuccessAction |
  CreateShoppingListFromMenuItemsAction |
  CreateShoppingListFromMenuItemsSuccessAction |
  DeleteShoppingListAction |
  DeleteShoppingListSuccessAction |
  AddMenuItemToShoppingListAction |
  AddMenuItemToShoppingListSuccessAction |
  AddMenuItemsToShoppingListAction |
  AddMenuItemsToShoppingListSuccessAction |
  DeleteMenuItemFromShoppingListAction |
  DeleteMenuItemFromShoppingListSuccessAction |
  UpsertManualItemToShoppingListAction |
  UpsertManualItemToShoppingListSuccessAction |
  DeleteManualItemFromShoppingListAction |
  DeleteManualItemFromShoppingListSuccessAction |
  SetPurchasedStatusOnShoppingListAction |
  SetPurchasedStatusOnShoppingListSuccessAction |
  ListCellarItemsAction |
  ListCellarItemsSuccessAction |
  DeleteCellarItemAction |
  DeleteCellarItemSuccessAction |
  AddCellarItemAction |
  AddCellarItemSuccessAction |
  AddCellarItemToShoppingListAction |
  AddCellarItemToShoppingListSuccessAction |
  SetCellarItemBrokenAction |
  SetCellarItemBrokenSuccessAction |
  UnsetCellarItemBrokenAction |
  UnsetCellarItemBrokenSuccessAction |
  DeleteCellarItemFromShoppingListAction |
  DeleteCellarItemFromShoppingListSuccessAction |
  AddPartyToShoppingListAction |
  AddPartyToShoppingListSuccessAction |
  DeletePartyFromShoppingListAction |
  DeletePartyFromShoppingListSuccessAction |
  UpsertRecipeAction |
  UpsertRecipeSuccessAction |
  DeleteAction |
  DeleteSuccessAction |
  GetProfilePageAction |
  GetProfilePageSuccessAction |
  UpsertPartyAction |
  UpsertPartySuccessAction |
  DeletePartyAction |
  DeletePartySuccessAction |
  ListPartiesAction |
  ListPartiesSuccessAction |
  ListPremenusAction |
  ListPremenusSuccessAction |
  UpsertPremenuAction |
  UpsertPremenuSuccessAction |
  GetPremenuAction |
  GetPremenuSuccessAction |
  StartPremenuMenuEditionAction |
  StartPremenuMenuEditionSuccessAction |
  EndPremenuMenuEditionAction |
  UsePremenuAction |
  ListMenuItemsUserAction |
  ListMenuItemsUserSuccessAction |
  ListMenuItemsAction |
  ListMenuItemsSuccessAction |
  GetPartyAction |
  GetPartySuccessAction |
  ListEditableAction |
  ListEditableSuccessAction;


export interface GetAccountAction extends Action {
  type: ActionTypes.GetAccount,
}

export interface GetAccountSuccessAction extends Action {
  type: ActionTypes.GetAccountSuccess,
  account: Account,
}

export interface SetProfileAction extends Action {
  type: ActionTypes.SetProfile,
  profile: string,
}

export interface SaveProfileAction extends Action {
  type: ActionTypes.SaveProfile,
  profile: Profile,
}

export interface SaveProfileSuccessAction extends Action {
  type: ActionTypes.SaveProfileSuccess,
  item: Profile,
}

export interface DeleteProfileAction extends Action {
  type: ActionTypes.DeleteProfile,
  profileId: string,
}


export type AccountActions =
  GetAccountAction |
  GetAccountSuccessAction |
  SetProfileAction |
  SaveProfileAction |
  SaveProfileSuccessAction |
  DeleteProfileAction;

export interface GetRecommendationsAction extends Action {
  type: ActionTypes.GetRecommendations,
}

export interface GetRecommendationsSuccessAction extends Action {
  type: ActionTypes.GetRecommendationsSuccess,
  item: Recommendations,
}

export type RecommendationsActions =
  GetRecommendationsAction |
  GetRecommendationsSuccessAction;


export type AllActions =
  CoreAllActions |
  AccountActions |
  BasicActions |
  RecommendationsActions |
  { type: 'EPIC_FAILURE', error: Error };
